import React from "react";

const Metodos =( props) => {
    let {className} = props;
    return(
        <div className={`${className}wc f01 metodosIcons `}>
           <small>Pagos Procesados por </small>
            <div className="iconos f01 mt-3 mt-sm-0">
                <img src="/img/pse.png" alt=""/>
                <img src="/img/dinners.png" alt=""/>
                <img src="/img/amex.png" alt=""/>
                <img src="/img/mastercard.png" alt=""/>
                <img src="/img/visa.png" alt=""/>
                {props.epayco &&
                <img src="/img/epayco.png" alt=""/>}

            </div>
        </div>
    )
}

export default Metodos;