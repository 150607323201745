import React from "react";
import styled from '@emotion/styled'


const ContModalCC = styled.section`
    position:relative;
    border-radius:10px;
    padding:80px 1em 1em;
    background:white;
    max-width:650px;
    box-shadow:0 3px 10px rgba(0,0,0,.5);
    font-size:18px;
    
    opacity: 0;
    transform: scale(.8);
    transition: all 600ms cubic-bezier(0.175, 0.885, 0.320, 1.275);
    
    .title{
      
        font-size:40px;
        line-height:1.5em;
        margin:0;
    }
    
    .subtitle{
       font-size:24px;
    }
    
    .iconoCine{
        position:absolute;
        top:0;
        left:50%;
        transform:translate(-50%,-50%);
        width:30%;
        max-width:150px;
    }
    
    .close{
        position:absolute;
        top:1em;
        right:1em;
        width:20px;
        cursor:pointer;
       
    }
    
    .iconoF{
        max-width:147px;
        margin: 0 auto 1em;
    }
    
   
    h3{
        max-width:480px;
        margin:0 auto 1em; 
    }
    
    .btn-t{
        max-width:320px;
    }
    
`

/*const ModalCC = ({bono,codigo,onClose,mainAction})=> {
    return(
        <ContModalCC className={"col-12 col-lg-7 col-xl-6 bgb mx-auto text-center cColombia"}>
            <div className="iconoCine">
                <img src="/img/icono.svg" alt="" className={"imgr"}/>
            </div>
            
            <div className="close" onClick={() => onClose()}>
                <img src="/img/iconos/close.svg" alt="" className={"imgr"}/>
            </div>
            <h3 className={"fl"}>¡Ganaste un bono <span className={"cv fwb"}><b>${bono}</b></span> para la compra de tu boleta en Fandango!</h3>
            
            <p>Así de fácil, solo por recargar en línea. este es el código para redimir tu bono.</p>

            <div className="iconoF">
                <img src="/img/fandango-logo.svg" alt="" className={"imgr"}/>
            </div>

            <h1 className={"fwb mb-4"}>{codigo}</h1>

           <div className={"py-3"}>
               <small>Tu código se ha enviado al correo electrónico registrado</small>
           </div>
            <div className="col-12">
                <button className={"btn-t wc"}
                onClick={mainAction}
                >Redimir código</button>
            </div>

        </ContModalCC>
    )
}*/

const ModalCC = ({onClose,mainAction})=> {
    return(
        <ContModalCC className={"col-12 col-lg-7 col-xl-6 bgb mx-auto text-center cColombia"}>
            <div className="iconoCine">
                <img src="/img/cineIcon.svg" alt="" className={"imgr"}/>
            </div>

            <div className="close" onClick={() => onClose()}>
                <img src="/img/iconos/close.svg" alt="" className={"imgr"}/>
            </div>
            <p className={"ca title"}>Raspa y Gana con Movistar</p>
            <p className={"ca subtitle"}>Así de fácil por recargar en línea</p>

            <p className={"mb-0 cg"}>Haz clic en el siguiente enlace para participar</p>
            <p className={"mb-4 cg"}>¡Buena Suerte!</p>

            <div className="col-12 pb-3">
                <button className={"btn-t wc"}
                        onClick={mainAction}
                >Jugar Raspa y Gana</button>
            </div>

        </ContModalCC>
    )
}

export default ModalCC;