import React, {Component} from "react";
import ReactDOM from "react-dom";
import {CSSTransition} from 'react-transition-group';

const Modal = props => {
    return (

        <CSSTransition
            in={props.show}
            timeout={600}
            classNames="fade"
            unmountOnExit
        >
            <div className={"modal-container f02"}>

                <div className={`sombra-modal ${props.masOscuro && "masOscuro"}`}></div>
                <div className="area wc f01 position-relative px-2 ">
                    {!props.reset ?
                    <div className={`card ${props.className}`}>
                        {!props.hideClose &&
                        <div className="close close2"
                             onClick={props.onClose}
                        >
                            {/*<i className={"fa fa-times"}></i>*/}
                            <img src="/img/iconos/close.svg" alt="" className={"imgr"}/>
                        </div>
                        }
                        <div className="content p-md-3 ">
                            {props.children}
                        </div>
                    </div>
                        :
                        <div className={` wc gira`}>
                            {props.children}
                        </div>
                    }
                </div>

            </div>
        </CSSTransition>

    )
}


class ShowModal extends Component {



    render() {
        // React does *not* create a new div. It renders the children into `domNode`.
        // `domNode` is any valid DOM node, regardless of its location in the DOM.
        return ReactDOM.createPortal(
            <Modal {...this.props} >{this.props.children}</Modal>,
            document.getElementById("cont-modal")
        );
    }

}

export default ShowModal;



