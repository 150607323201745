import React, {Component} from "react";
import moment from "moment";
import Formulario from "./formulario/formulario";
import {withRouter} from "react-router";
import getUrlVars from "./helpers/urlparameters";
import Banner from "./sections/banner";
import Cookies from 'universal-cookie';
import TagManager from '../.././Helpers/tagManagerHelper';
import { getDeadline } from "../../Helpers/getDeadline";

const cookies = new Cookies();


class Home extends Component {

    constructor(props) {
        super(props);

        this.state = {
            number: "",
            amount: "20000",
            canal: "",
            responseData: "",
            bannerActivo: 0,
            megas: this.props.megas,
            megas01: this.props.megas01,

        }

    }

    componentWillMount() {
        this.dateRedirect();

        let getVariableParam = (parametro) => {
            var results = new RegExp('[\?&]' + parametro + '=([^&#]*)').exec(window.location.href);
            if (results == null) {
                return null;
            } else {
                return decodeURI(results[1]) || 0;
            }
        }


        // const params = new URLSearchParams(this.props.location.search);
        // let getVariableParam = (parametro) => {
        //     console.log("parametroooos",params.get(parametro))
        //     return params.get(parametro) || "";
        // };
        let slider = getVariableParam("slider");
        if (slider) {
            if (slider == 1 || slider == 2 || slider == 3) {
                this.setState({
                    bannerActivo: slider - 1
                })
            }

        }

        let dataLayer = {
            'event': 'trackPageview', // Nombre del evento que se utiliza en GTM.
            'pageName': '/recarga_en_linea/visualizacion' // Path y nombre de la página virtual (String). Requirido.
        }
        TagManager.setNewTagManager(dataLayer);
        this.tagManagerImpProduct();

        let {location} = this.props;
        if (getVariableParam('numero') && getVariableParam('valor_recarga')) {
            this.setState({
                number: getUrlVars(location.search).numero,
                amount: getUrlVars(location.search).valor_recarga,
            })
        }
        let valor_url = cookies.get('valor_url')
        let numero_url = cookies.get('numero_url')
        let canal_url = cookies.get('canal_url')

        if(numero_url != "null"){
            // console.log("estamos numero_url", numero_url)
            this.setState({number : numero_url})
        }
        if(valor_url != "null"){
            this.setState({amount:valor_url})
            // console.log("estamos valor_url", valor_url)
        }
        if(canal_url != "null"){
            // console.log("estamos canal_url", canal_url)
        }
    }

    componentWillReceiveProps(newprops) {
        if (newprops.data.amount !== "") {
            this.setState({
                amount: newprops.data.amount,
                responseData: newprops.responseData
            })
        }
        if (newprops.megas !== this.state.megas) {
            this.setState({
                megas: newprops.megas
            })
        }

        if (newprops.megas01 !== this.state.megas01) {
            this.setState({
                megas01: newprops.megas01
            })
        }
    }

    tagManagerImpProduct() {
        let dataLayer = {
            'productos': this.props.productos,
            'event': 'trackProdImp', // Nombre del evento que se utiliza en GTM.
        }
        TagManager.setNewTagManager(dataLayer);
    }

    async dateRedirect(){
        const deadline =  await getDeadline();    

        if(deadline.status === false || moment().isSameOrAfter(moment(deadline.data[0].time.date))){
                window.location.href = 'https://prepago.movistar.co/recargas';
        }
    }



    render() {
        return (
            <div className={"wc home-recarga f01 algs "}>
                <div className="col-12 col-md-6 px-0 cont-form ">
                    {!this.props.megas ?
                        <Formulario
                            loading={this.props.loading}
                            submit={this.props.submit}
                            data={{
                                number: this.state.number,
                                amount: this.state.amount,
                            }}
                            responseData={this.state.responseData}
                            loadingButton={this.props.loadingButton}
                            banner={this.props.banner}
                            productos={this.props.productos}
                            valor_recarga={this.props.valorRecarga}
                        /> :

                        <div className="wc hc f02 pt-5 pt-md-0">
                            <h3 className={"fl wc text-center pb-4"}>Recarga en línea</h3>
                            <div className="col-3 mx-auto px-0 pt-3 pb-2 ">
                                <img src="/img/icon-late.svg" alt="" className={"imgr"}/>
                            </div>
                            <div className="wc text-center">
                                {/*nùmero de la linea*/}
                                <h4 className={"fw mb-0"}>{this.state.number}</h4>
                                <h3 className={"fl pt-3"}>¡Sin datos para navegar! 🤔 No te preocupes,<br/>aquí puedes recargar aún sin datos.</h3>
                            </div>
                            <div className={"wc"}>
                                <div className=" pb-4 mx-auto text-center"
                                     style={{width: "100%", maxWidth: "350px", fontSize: "18px"}}>
                                    <small className={"fl d-block"}>Estamos validando tu línea Movistar esto puede
                                        tardar unos segundos, espera por favor.
                                    </small>
                                </div>
                                {this.state.megas01 ?
                                    <div className="container-loading pt-4">
                                        <div className="LoaderBalls mx-auto">
                                            <div className="LoaderBalls__item"></div>
                                            <div className="LoaderBalls__item"></div>
                                            <div className="LoaderBalls__item"></div>
                                        </div>
                                    </div> :
                                    <div className={"wc"}>
                                        <div className={"col-12 col-md-8 col-lg-6 mx-auto"}>
                                            <button onClick={this.props.showViewMegas} className="btn-t text-center wc">
                                                ¡Esperar un poco más!
                                            </button >
                                        </div>
                                    </div>}
                            </div>
                        </div>
                    }

                </div>
                <div className="d-none d-md-block col-md-6 px-0 ">
                    <Banner
                        bannerActivo={this.state.bannerActivo}
                        maantenimiento={this.props.mantenimiento}
                    />
                </div>
            </div>
        )
    }
}

export default withRouter(Home);
