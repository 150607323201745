import ServiceApi from "../components/componentes/requests";
import config from "../config/config";

export const getDeadline = async () => {

    let obj = {};

    await ServiceApi({},`${config.amonServices}/services/deadline`, 'GET')
        .then(response => {
            if(response.success){

                obj.data = response.data;
                obj.status = true;

            }else{
                obj.status = false;
                obj.message = 'No se pudo consulta';
            }

        })
        .catch(error => {
            obj.status = false;
            obj.message = error;

        });

    return obj;

}
