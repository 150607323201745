
// Identify Client
export const egoiRender = (transaction) =>{

    let _egoiaq = _egoiaq || [];
    let number  = transaction.customer.mobilePhone;

    let u =(("https:" === document.location.protocol) 
            ? "https://egoimmerce.e-goi.com/" 
            : "http://egoimmerce.e-goi.com/");

    const script = document.createElement('script');

    script.type='text/javascript';
    script.defer=true;
    script.async=true;
    script.src = u + 'egoimmerce.js';
    document.body.appendChild(script);

    _egoiaq.push(['setClientId', "221772"]);
    _egoiaq.push(['setListId', "134"]);
    _egoiaq.push(['setSubscriber', number]);
    _egoiaq.push(['setTrackerUrl', u+'collect']);
    _egoiaq.push(['trackPageView']);

    let cart = {
        name:    'recarga ' + transaction.detail.x_amount,
        price:    transaction.detail.x_amount,  
        status:   transaction.detail.x_response,  
        refPayco: transaction.detail.x_ref_payco
    };

    let total  = Number(parseFloat(cart.price).toFixed(2));
    let estado_transaccion = cart.status.toLowerCase();
    let item_name = cart.name + " " + estado_transaccion;

    // Add product to cart
    _egoiaq.push([
      'addEcommerceItem',
      item_name,
      item_name,
      ["recargas"],
      total,
      "1" 
    ]); 

    let trackEcommerceCartUpdate = 'trackEcommerceCartUpdate';

    // Action based on transaction status
    switch (estado_transaccion) {

        case "aceptada":
        // Send order confirmed
        _egoiaq.push(['trackEcommerceOrder',cart.refPayco.toString(),total]);
        break;
        case "rechazada":
        _egoiaq.push([trackEcommerceCartUpdate,total]);
        _egoiaq.push(['trackGoal', 2]);
        break;
        case "fallida":
        _egoiaq.push([trackEcommerceCartUpdate,total]);
        _egoiaq.push(['trackGoal', 3]);
        break;
        case "pendiente":
        _egoiaq.push([trackEcommerceCartUpdate,total]);
        _egoiaq.push(['trackGoal', 4]);
        break;
        case "abandonada":
        _egoiaq.push([trackEcommerceCartUpdate,total]);
        _egoiaq.push(['trackGoal', 5]);
        break;
        case "expirada":
        _egoiaq.push([trackEcommerceCartUpdate,total]);
        _egoiaq.push(['trackGoal', 6]);
        break;
        case "cancelada":
        _egoiaq.push([trackEcommerceCartUpdate,total]);
        _egoiaq.push(['trackGoal', 7]);
        break;
        default:
        
    }
};
