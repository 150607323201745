/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import './assets/index.css';
import logo from './assets/logo.svg';
import close from './assets/close.svg';
import TagManager from '../../../../Helpers/tagManagerHelper';


const Popup = () => {
    const [showPopup, setShowPopup] = useState(false);
    const closePopup = (eventLabel = 'descubre aquí tu regalo') => {
        updateTag(eventLabel)
        setShowPopup(false)
    };

    const updateTag = (eventLabel = 'descubre aquí tu regalo') => {
        const dataLayer = {
            'event': 'trackEvent',
            'eventCategory': 'popup-oferta-siempre-ganas',
            'eventAction': 'click',
            'eventLabel': eventLabel,
        };
        TagManager.setNewTagManager(dataLayer)
    }

    return (

        <React.Fragment>
            {showPopup ?
                <div className='popup'>
                    < div className='popup_inner' >
                        <a className="close-btn" onClick={() => {
                            closePopup('cerrar')
                        }}><img src={close} className="App-close" alt="close" /></a>
                        <a onClick={() => {
                            closePopup('descubre aquí tu regalo')
                        }} href="https://www.movistar.com.co/oferta-siempre-ganas" target="_blank" rel="noreferrer"><img src={logo} className="App-logo" alt="logo" /></a>
                    </div >
                </div >
                : null}
        </React.Fragment>


    );
};

export default Popup;