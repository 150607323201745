import axios from "axios";
import React, { Component, Fragment } from "react";
import Collapse from "react-collapse";
import { withRouter } from "react-router";
import SimpleReactValidator from "simple-react-validator";
import Cookies from 'universal-cookie';
import TagManager from '../../../Helpers/tagManagerHelper';
import { GetSanitizedUrl, GetVariableParam } from "../../../utils";
import CampoIcon from "../campo";
import Metodos from "../sections/metodos";
import config from "./../../../config/config";
import Popup from "./Popup";


var tagManagerArgs = (params) => {
    let  dataLayer = {
            'event': 'trackEvent', // Nombre del evento que se utiliza en GTM.
            'eventCategory' : params.eventCategory,
            'eventAction': params.eventAction,
            'eventLabel': params.valor // Path y nombre de la página virtual (String). Requerido.
    }
    TagManager.setNewTagManager(dataLayer);

};
var tagManagerView = (params) => {

    let dataLayer = {
            'event': 'trackPageview', // Nombre del evento que se utiliza en GTM.
            'pageName': params.pageName // Path y nombre de la página virtual (String). Requerido.
        }

    TagManager.setNewTagManager(dataLayer);
};

var tagManagerTransaction = (Transaccion) => {
    let dataLayer = {
        'event':'funnel_prepago',
        'valor': Transaccion.amount,
        'flujo': 'TYP'
    };
    TagManager.setNewTagManager(dataLayer);
}


const cookies = new Cookies();

class Response extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: this.props.loading,
            colorTrx:"",
            color_copy_err:"",
            recargaExitosa:false,
            transaction_date: "",
            customer_ip: "",
            line_pay: "",
            id_invoice: "",
            no_invoice: "",
            transaction_state: "",
            ref_payco: "",
            amount: "",
            response_reason_text: "",
            email:"",
            loading_mail: false,
            loadingEnd: false,
            respuesta_email:"",
            print_class:"",
            intentos:0,
            intentando: this.props.realizandoRecarga,
            responseData : this.props.responseData,
            pendienteARealizar:true
        }


        this.sendEmail = this.sendEmail.bind(this);
        this.setValue = this.setValue.bind(this);
        this.print = this.print.bind(this);
        this.requestEnd = this.requestEnd.bind(this);
        this.newRecharge = this.newRecharge.bind(this);



        this.validator = new SimpleReactValidator({
            messages: {
                required: "Debe ingresar un :attribute",
            },

            validators:{
                email:{
                    message: 'Debe ingrsar un :attribute válido',
                    rule: (val) => {
                        let patron = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                        return patron.test(val)
                    },
                }
            }
        })
    }

    setTagmanager(transaction){
        switch (transaction.transaction_state) {
            case 'Rechazada' :
                tagManagerView({
                    'pageName': "/recargas_prepago/Fallida/Epayco"
                });
            break;
            case'Pendiente' :
                tagManagerView({
                    'pageName': "/recargas_prepago/Pendiente/Epayco"
                });

                break;
            case 'Aceptada' :
                tagManagerView({
                    'pageName': "/recargas_prepago/Aceptada/Epayco"
                });
                tagManagerTransaction(transaction);
                break;
            default :
                tagManagerView({
                    'pageName': "/recargas_prepago/Aceptada/Epayco"
                })
                break;
        }

    }

    componentWillReceiveProps(prevProps){
        if(prevProps.responseData !== this.state.responseData){
            this.setState({
                responseData: prevProps.responseData
            }, ()=>{
                this.setTagmanager(this.state.responseData)
            })
        }
        if(prevProps.recargaExitosa !== this.state.recargaExitosa){
             this.setState({
        recargaExitosa: prevProps.recargaExitosa
            })
        }

        if(prevProps.realizandoRecarga !== this.state.intentando){
             this.setState({
        intentando: prevProps.realizandoRecarga
            })
        }
    }

    componentWillMount(){
        cookies.remove('valor_url')
        cookies.remove('canal_url')
        cookies.remove('numero_url')

        if(this.props.location.search !== "") {
            let getVariableParam = (parametro) => {
                var results = new RegExp('[\?&]' + parametro + '=([^&#]*)').exec(window.location.href);
                if (results == null){
                    return null;
                }
                else {
                    return decodeURI(results[1]) || 0;
                }
            };
            const ref_payco = getVariableParam("ref_payco") || false;
            const x_ref_payco = getVariableParam("x_ref_payco") || false;
            const x_cust_id_cliente = getVariableParam("x_cust_id_cliente") || false;
            const novum = getVariableParam("novum");
            this.setState({novum: novum});
            const urlCallBack = getVariableParam("urlcallback");
            this.setState({urlcallback: urlCallBack});

            if (ref_payco === '' && x_ref_payco === '') {
                // window.location.href = ("/")
            }
            if (cookies.get('isreload')) {
                this.props.getDataServer(ref_payco, x_ref_payco, false, x_cust_id_cliente)
            }else {
                cookies.set('isreload', true, {expires : new Date(Date.now()+ 100000)});
                this.props.getDataServer(ref_payco, x_ref_payco, true, x_cust_id_cliente)
            }

        }else {
            // window.location.href =("/")
        }
    }


    requestEnd(){
        tagManagerArgs({
            valor: 'finalizar',
            eventAction: 'confirmacion compra',
            eventCategory: 'recarga en linea'
        })

        this.setState({loadingEnd:true});
        if(this.state.novum){
            this.setState({
                postMessage : true,
                urlPost : config.urlFront,
            });
            let urlCallBack = GetVariableParam("urlcallback") || "";
            const decodedUrlCallBack = GetSanitizedUrl(urlCallBack);
            window.parent.postMessage("callback", "*");
            window.location.assign(decodedUrlCallBack);
        }else {
            window.location.href = 'https://www.movistar.co'
        }
    }

    newRecharge(){
        tagManagerArgs({
            valor: 'finalizar',
            eventAction: 'confirmacion compra',
            eventCategory: 'recarga en linea'
        });

        this.setState({loadingEnd:true});
        if(this.state.novum){
            this.setState({
                postMessage : true,
                urlPost : config.urlFront,
            })
            window.location.href = '/'
        }else {
            window.location.href = '/'
        }
    }

    componentDidUpdate(prevProps, prevState){
        if (prevState.postMessage !== this.state.postMessage) {
            window.parent.postMessage({event:'urlTdc',url:`${config.urlFront}/?canal=app&novum=true&tipo_servicio=movil&tipo_pago=movistar`},"*");
            // window.location.href = '/'
        }
    }

    sendEmail(){
        tagManagerArgs({
            valor: 'enviar por mail',
            eventAction: 'confirmacion compra',
            eventCategory: 'recarga en linea'
        })

        if (this.validator.fieldValid('email')) {
            this.setState({loading_mail : true, respuesta_email:""})
            // let dataToSendMail = {
            //     ref_payco : this.state.responseData.ref_payco,
            //     email : this.state.email
            // }
            axios({
                method: 'get',
                url: `${config.URL_SEND_MAIL}?transaction_id=${this.state.responseData.ref_payco}&email_adicional=${this.state.email}`
            }).then((responseSendMail) => {
                    if(responseSendMail.data.success === 'ok'){
                        this.setState({
                            loading_mail: false,
                            respuesta_email: "¡Comprobante enviado con éxito!",
                            color_copy_err: "cv"
                        })
                    } else {
                        this.setState({
                            showError:true,
                            loading:false,
                            loading_mail: false,
                            respuesta_email: "¡Hubo un error al enviar el comprobante!",
                            color_copy_err: "cr"
                        });
                    }
                }).catch( err =>{
                    this.setState({
                        showError:true,
                        loading:false,
                        loading_mail: false,
                        respuesta_email: "¡Hubo un error al enviar el comprobante!"
                    });
            });            
        }
        else {
            this.validator.showMessages();
            // rerender to show messages for the first time
            this.forceUpdate();
        }
    }

    print(){
        tagManagerArgs({
            valor: 'imprimir',
            eventAction: 'confirmacion compra',
            eventCategory: 'recarga en linea'
        })

        this.setState({print_class:"dn"})
        setTimeout(() => {
            window.print()
            this.setState({print_class:""})

        }, 100);

    }

    setValue(event) {
        this.setState({
            [event.target.name]:event.target.value,
        })
    }

    redirectAppleStore(){
        window.open('https://itunes.apple.com/es/app/mi-movistar/id1246644017?mt=8')
    }

    redirectPlayleStore(){
        window.open('https://play.google.com/store/apps/details?id=movistar.android.app')
    }
    render() {
        return (
            <div className={"response-container wc pt-md-3 "}>
                <div className="container">
                    <article className={"f01 algfs wc"}>


                        {this.state.loading ?
                            <Fragment>
                                <section className={"col-12 col-md-6 pt-md-5"}>
                                    <div className="titulo py-4 text-center ">
                                        <span className={'fl'}>Resultado de la transacción</span>
                                    </div>
                                    <div className="tab col-12 f01 jcfs pt-4 pb-3">
                                        <div className="col-12 line">
                                            <div className="loadingData  py-1"></div>
                                            <div className="loadingData my-2 py-3"></div>
                                        </div>
                                        <div className="col-12 col-sm-6 line">
                                         <div className="loadingData  py-1"></div>
                                         <div className="loadingData my-2 py-3"></div>
                                        </div>
                                        <div className="col-12 col-sm-6 line">
                                            <div className="loadingData  py-1"></div>
                                            <div className="loadingData my-2 py-3"></div>
                                        </div>
                                        <div className="col-12 col-sm-6 line">
                                            <div className="loadingData  py-1"></div>
                                            <div className="loadingData my-2 py-3"></div>
                                        </div>
                                        <div className="col-12 col-sm-6 line">
                                            <div className="loadingData  py-1"></div>
                                            <div className="loadingData my-2 py-3"></div>
                                        </div>
                                        <div className="col-12 col-sm-6 line">
                                            <div className="loadingData  py-1"></div>
                                            <div className="loadingData my-2 py-3"></div>
                                        </div>
                                    </div>
                                    <div className="col-12 line f01 px-0">
                                        <div className="col- 12 loadingData my-3 m py-1"></div>
                                        <div className="loadingData mx-2 py-3 col-8"></div>
                                        <div className="loadingData mx-2 py-3 col-3"></div>
                                    </div>
                                </section>
                                <section className={"col-12 col-md-6"}>
                                    <div className="titulo py-4 text-center">
                                        <span>Comprobante de compra</span>
                                    </div>
                                    <div className="tab col-12 jcfs pb-3 px-0">
                                        <div className=" banner f01 mb-3 wc pt-4 pb-2">
                                            <div className="col-4 loadingData py-4">

                                            </div>
                                            <div className="col-8 col-sm-6 line">
                                                <div className="loadingData  py-1"></div>
                                                <div className="loadingData my-2 py-3"></div>
                                            </div>
                                        </div>
                                        <div className="col-12 f01">
                                            <div className="col-12 col-sm-6 line">
                                                <div className="loadingData  py-1"></div>
                                                <div className="loadingData my-2 py-3"></div>
                                            </div>
                                            <div className="col-12 col-sm-6 line">
                                                <div className="loadingData  py-1"></div>
                                                <div className="loadingData my-2 py-3"></div>
                                            </div>
                                            <div className="col-12 col-sm-6 line">
                                                <div className="loadingData  py-1"></div>
                                                <div className="loadingData my-2 py-3"></div>
                                            </div>
                                            <div className="col-12 col-sm-6 line">
                                                <div className="loadingData  py-1"></div>
                                                <div className="loadingData my-2 py-3"></div>
                                            </div>
                                            <div className="col-12 col-sm-6 line">
                                                <div className="loadingData  py-1"></div>
                                                <div className="loadingData my-2 py-3"></div>
                                            </div>
                                            <div className="col-12 col-sm-6 line">
                                                <div className="loadingData  py-1"></div>
                                                <div className="loadingData my-2 py-3"></div>
                                            </div>

                                        </div>


                                    </div>
                                    <div className="col-12 f01 px-0 loadingData py-3 mt-2">

                                    </div>
                                </section>
                            </Fragment> :
                            <Fragment>
                                <section className={"col-12 col-md-6 px-0 px-md-2"}>
                                    <div className="titulo my-4 text-center fl ">
                                        <span>Resultado de la transacción</span>
                                    </div>
                                    <div className="tab col-12 f01 jcfs pt-4 pb-3">
                                        <div className="col-12 line">
                                            <span>EMPRESA</span>
                                            <p>COLOMBIA TELECOMUNICACIONES S.A. ESP</p>
                                        </div>
                                        <div className="col-12 col-sm-6 line ">
                                            <span>NIT</span>
                                            <p>830.122.566-1</p>
                                        </div>
                                        <div className="col-12 col-sm-6 line">
                                            <span>FECHA Y HORA</span>
                                            <p>{this.state.responseData.transaction_date}</p>
                                        </div>
                                        <div className="col-12 col-sm-6 line">
                                            <span>DESCRIPCIÓN</span>
                                            <p> Recarga celular {this.state.responseData.line_pay}</p>
                                        </div>
                                        <div className="col-12 col-sm-6 line">
                                            <span>IP ORIGEN</span>
                                            <p>{this.state.responseData.customer_ip}</p>
                                        </div>
                                        <div className="col-12 col-sm-6 line">
                                            <span>MÉTODO DE PAGO</span>
                                            <div className="col-12"></div>
                                            {
                                                this.state.responseData.franchise === "DP" ?
                                                    <img src={"/img/franquicia/dp.png"} height={"60px"}  width={"auto"} alt=""/>
                                                    : [
                                                        (this.state.responseData.franchise ?
                                                                <img src={`https://369969691f476073508a-60bf0867add971908d4f26a64519c2aa.ssl.cf5.rackcdn.com/images/franquicias/${this.state.responseData.franchise}.png`} alt=""/>
                                                            : ""
                                                        )
                                                    ]
                                            }
                                        </div>

                                    </div>
                                    <small><i className={this.state.print_class}>Enviar una copia del comprobante de transacción</i></small>
                                    <div className={`col-12 f01 px-0 ${this.state.print_class}`}>
                                        <div className="col-8 px-0">
                                            <CampoIcon
                                                onChange={this.setValue}
                                                name={"email"}
                                                icon={"envelope"}
                                                placeholder={"Tu correo electrónico"}
                                                value={this.state.email}
                                            />
                                        </div>
                                        <div className="col-4 cont-btn-t pr-0">
                                            <button className={`btn-b wc ${this.state.loading_mail ? "loading3" : ""}`}
                                                    disabled={this.state.loading_mail}
                                                    onClick={this.sendEmail}
                                            >
                                                {this.state.loading_mail ? "" : "Enviar"}
                                            </button>
                                        </div>
                                        <div className="wc">
                                            <Collapse isOpened={this.validator.message('email', this.state.email, 'required|email') !== undefined}>
                                                <small className={"error"}>{this.validator.message('email', this.state.email, 'required|email')}</small>
                                            </Collapse>
                                        </div>
                                    </div>
                                    {this.state.respuesta_email !== '' &&
                                    <div className="col-12 col-sm-6 line">
                                        <p className={`${this.state.color_copy_err}`}>{this.state.respuesta_email}</p>
                                    </div>
                                    }
                                </section>
                                <section className={"col-12 col-md-6 section-green px-0 px-md-2"}>
                                    <div className="titulo my-4 text-center fl">
                                        <span>Comprobante de compra</span>
                                    </div>

                                    <div className="tab col-12 jcfs px-0">
                                        <div className=" banner f01 mb-2 wc pt-4 pb-2">
                                            <div className="col-3">
                                                <img src="/img/laptopEmogi.png" alt="" className={"imgr"}/>
                                            </div>
                                            <div className="col-8 line">
                                                <span>REFERENCIA</span>
                                                <p>{this.state.responseData.id_invoice}</p>
                                            </div>
                                        </div>
                                        <div className="col-12 f01 jcfs otros-datos py-3">
                                            <div className="col-12 col-sm-6 line">
                                                <span>NRO.DE RECIBO</span>
                                                <p>{this.state.responseData.ref_payco}</p>
                                            </div>
                                            <div className="col-12 col-sm-6 line ">
                                                <span>ESTADO TRANSACCIÓN</span>
                                                <p className={this.state.responseData.colorTrx}> <strong>Transacción {this.state.responseData.transaction_state} </strong>  </p>
                                            </div>
                                            <div className="col-12 col-sm-6 line">
                                                <span>REFERENCIA EPAYCO</span>
                                                <p>{this.state.responseData.ref_payco}</p>
                                            </div>
                                            <div className="col-12 col-sm-6 line">
                                                <span>VALOR</span>
                                                <p>${this.state.responseData.amount}</p>
                                            </div>
                                            {this.state.responseData.colorTrx ==='cv' ?
                                            <div className="col-12 col-sm-6  line ">
                                                <span>ESTADO RECARGA</span>
                                                    <div className={"wc"}>
                                                        <p className={"cv mb-0"}><strong> En los próximos minutos tu <br/>recarga será aplicada y recibirás <br/>un mensaje de confirmación.​</strong></p>
                                                    </div>
                                            </div> :
                                            <div className="col-12 col-sm-6  line ">
                                            </div>
                                            }

                                            {this.state.responseData.colorTrx === 'cv' &&
                                            <div className="col-12 col-sm-6  line ">
                                                <span>AUTORIZACIÓN CUS</span>
                                                <p>{this.state.responseData.cus}</p>
                                            </div>
                                            }

                                        </div>


                                    </div>

                                    <div className="col-12 f01 px-0">
                                        <button className={`ca btn-g wc ${this.state.print_class}`} onClick={() => this.print()}>
                                            <img src="/img/print.png" alt="" className={"d-inline-block mr-2"} style={{height:"20px", width:"auto"}}/> Imprime este comprobante
                                        </button>
                                    </div>

                                   <div className=" wc py-3 f01">
                                       <div className="col-12 col-sm-6 col-md-12 col-lg-6 pl-sm-0  pr-sm-2 pr-md-0 pr-lg-2 mb-3 mb-sm-0 mb-md-2 mb-lg-0">
                                           <button className={"wc btn-t bg-azul"} onClick={() => this.newRecharge()}>Realizar otra recarga</button>
                                       </div>
                                       <div className="col-12 col-sm-6 col-md-12 col-lg-6 pr-sm-0  pl-sm-2 pl-md-0 pl-lg-2">
                                           <button className={"wc btn-t"} onClick={() => this.requestEnd()}>Finalizar</button>
                                       </div>

                                   </div>
                                </section>

                            </Fragment>
                        }

                    </article>

                    <div className={` ${ this.props.loading ? "opn" : "" } f01 py-2 ${this.state.print_class} col-12 my-3  ${this.state.pendienteARealizar ? "my-md-0 " :"my-md-5"} px-0 tr`}>
                        {/*<div className="cont-btn-t col-12 col-md-6 col-lg-4 m-md-auto">*/}
                            {/*<button className={`${this.state.loadingEnd ? "loading" : ""} btn-t wc`}*/}
                                    {/*disabled={this.state.loadingEnd}*/}
                                    {/*onClick={this.requestEnd}*/}
                            {/*>*/}
                                {/*{this.state.loadingEnd ? "" : "Finalizar"}*/}
                            {/*</button>*/}
                        {/*</div>*/}
                        <div className="col-12 col-md-6 mb-3 px-0 col-xl-5">
                            <div className="brd bgt p-3 f01 ">
                                <div className="col-3 col-sm-2 col-lg-1 px-0">
                                    <img src="/img/image.png" alt="" className={"imgr"}/>
                                </div>
                                <div className="col-8 col-sm-4 col-md-5 col-lg-4 pr-md-0">
                                    <p className={"cb mb-0"}>Descarga la app Mi Movistar</p>
                                </div>
                               <div className="col-12 col-sm-5 col-md-5 f01 col-lg-7 pt-3 px-0 pt-sm-0">
                                   <div className="col-8 col-sm-12 col-lg-6 px-0 px-sm-2 mb-3 mb-md-2 mb-lg-0 pr-lg-0">
                                        <a onClick={this.redirectAppleStore}>
                                       <img src="/img/marca01.png" alt="" className={"imgr"}/>
                                       </a>
                                   </div>

                                   <div className="col-8 col-sm-12 col-lg-6 px-0 px-sm-2 pr-lg-0">
                                        <a onClick={this.redirectPlayleStore}>
                                       <img src="/img/marca02.png" alt="" className={"imgr"}/>
                                       </a>
                                   </div>
                               </div>

                            </div>
                        </div>
                        <div className="col-md-6 col-xl-7 text-md-right">
                            <Metodos epayco className={"justify-content-sm-end "}/>
                        </div>


                    </div>

                </div>
                <Popup />
            </div>

        )
    }
}

export default  withRouter(Response);
