/*
Metodo utilizado para decodificar de base64 la url solicita para redireccion por novum
 */
export const GetSanitizedUrl = (urlCallBack) => {

    let urlSanitized  = "";
    //Se realiza esta validacion por que cuando se cancela la transaccion del checkout se agrega el parametro ref_payco con el signo "?"
    if (urlCallBack.includes("?ref_payco")){
        const Base64FromUrl = urlCallBack.split("?ref_payco")
        urlSanitized = Base64FromUrl[0];
    }else{
        urlSanitized = urlCallBack;
    }
    return window.atob(urlSanitized);
};

export const GetVariableParam = (parametro) => {
    var results = new RegExp('[\?&]' + parametro + '=([^&#]*)').exec(window.location.href);
    if (results == null){
        return null;
    }
    else {
        return decodeURI(results[1]) || 0;
    }
}