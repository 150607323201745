import TagManager from 'react-gtm-module'
export default {
    initializeTagManager (){
        const tagManagerArgs = {
            gtmId: 'GTM-PC5NL44'
        }
        TagManager.initialize(tagManagerArgs)
    },

    setNewTagManager(dataLayer){
        let tagManagerArgs = {
            gtmId: 'GTM-PC5NL44',
            dataLayer
        }
        TagManager.dataLayer(tagManagerArgs)
    }
}