import {fetch as fetchPolyfill} from 'whatwg-fetch'
import axios from 'axios'
// const ServiceApi = (data, url, typePeticion) => {
//     let request = "";
//     if (typePeticion === "POST") {
//         request = axios(url, {
//                 method: typePeticion,
//                 headers: {
//                     'Accept': 'application/json',
//                     'Content-Type': 'application/json',
//                 },
//                 data
//             })
//     } else {
//         request = axios(url, {
//             method: typePeticion,
//         })
//     }
//     return (new Promise((resolve, reject) => {
//         request.then()
//             .then(parseJSON => {
//                 resolve(parseJSON)
//             }).catch(error => {
//             reject(error);
//         });
//     }));
// };

const ServiceApi = (data, url, typePeticion) => {
    let request = "";
    if (typePeticion === "POST") {
        request = fetchPolyfill(url, {
                method: typePeticion,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data)
            })
    } else {
        request = fetchPolyfill(url, {
            method: typePeticion,
        })
    }
    return (new Promise((resolve, reject) => {
        request.then(parseJSON)
            .then(response => {
                resolve(response)
            }).catch(error => {
            reject(error);
        });
    }));
};

const parseJSON = (response) => {
    return response.json()
};

export default ServiceApi