const ENVIRONMENT = process.env.REACT_APP_ENV || process.env.NODE_ENV;
const url = {
    production: 'https://movistar.recargas.epayco.co',
    development: 'https://movistar.recargas.epayco.io',
    test: 'https://test.movistar.recargas.epayco.io',
    local: 'http://localhost:3001',
}[ENVIRONMENT]

const urlFront = {
    production: 'https://movistar.recargas.epayco.co',
    development: 'https://movistar.recargas.epayco.io',
    test: 'https://test.movistar.recargas.epayco.io',
    local: 'http://localhost:3000',
}[ENVIRONMENT]


//AMON
const amonServices = {
    production: "https://amon.epayco.co/api/movistar",
    development: "https://amon.epayco.io/api/movistar",
    test: "https://test.amon.epayco.io/api/movistar",
    local: "http://localhost:8001/api/movistar",

}[ENVIRONMENT];

const TerminosCondiciones = 'https://www.movistar.co/documents/117302439/404124706/TC+Recargas+Julio+2019.pdf/25e2a633-2799-ad29-0df8-db1a5c2d7c06?_ga=2.59257261.1252016567.1589550903-1899479429.1589550903';

const urlTimeAuth = 'https://TIME.movistar.com/time/v2/auth?client_id=col_epayco&redirect_uri=https%3A%2F%2Fmovistar.recargas.epayco.co%2Ftime_redirect&status=PRODUCCION&state='
const token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1c2VyXzRkOWMxNTQ3YzFjZWU0OGU5ZTM5OWJkYzliNDU2MGE4MzdiZjE1YjQxZTcyYWNlYjVjNDVlYTJhMDAwOTExZDkiLCJpYXQiOjE1Njk4NTg2MjEsImV4cCI6MTU2OTg2OTQyMX0.clv109G1wExfOUD6yPj-OUrnpnAiOdU5ET7uYm77Pmw'

const URL_SEND_MAIL = process.env.REACT_APP_URL_SEND_MAIL;

const config = {
    url,
    urlFront,
    urlTimeAuth,
    token,
    TerminosCondiciones,
    amonServices,
    URL_SEND_MAIL,
    ENVIRONMENT
};

export default config
