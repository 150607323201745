import React, {Component, Fragment} from 'react';
import Header from "./componentes/sections/header";
import {BrowserRouter, Route, Switch, Redirect} from "react-router-dom";
import ShowModal from "./componentes/modal/ShowModal";
import Tryagain from "./componentes/modal/trayagain";
import Home from "./componentes/home";
import Login from "./componentes/login";
import Response from "./componentes/response";
import serviceApi from "./componentes/requests"
import config from "../config/config"
import Cookies from 'universal-cookie';
import "./styles/styles.scss";
import TagManager from '../Helpers/tagManagerHelper'
import {createBrowserHistory} from 'history';
import Cargando from './componentes/cargando';
import {CSSTransition} from 'react-transition-group';
import TimeService from '../Helpers/TimeService'
import axios from 'axios';
import ModalCC from "./componentes/modalCC";
import Hmac256 from "crypto-js/hmac-sha256"
import {Btn, colors} from "./styles/helpers";
import ModalPromo from "./componentes/modal/modalPromo";
import {egoiRender} from "../utils/egoi";

const history = createBrowserHistory();
let contadorVerify = 5;
let contadorIntentos = 2;
// Get the current location.
const location = history.location;

// Listen for changes to the current location.
const unlisten = history.listen((location, action) => {
    // location is an object like window.location
    //console.log(action, location.pathname, location.state);
});

var productos = [
    {
        'name': 'recarga 3mil',
        'id': '7894164',
        'brand': 'recargas epayco',
        'category': 'Recargas',
        'price': '3000.00',
        'list': 'recargas epayco',
        'position': '0',
        "quantity": 1
    },
    {
        'name': 'recarga 6mil',
        'id': '7894164',
        'brand': 'recargas epayco',
        'category': 'Recargas',
        'price': '6000.00',
        'list': 'recargas epayco',
        'position': '1',
        "quantity": 1
    },
    {
        'name': 'recarga 15mil',
        'id': '7894164',
        'brand': 'recargas epayco',
        'category': 'Recargas',
        'price': '15000.00',
        'list': 'recargas epayco',
        'position': '2',
        "quantity": 1
    },
    {
        'name': 'recarga 25mil',
        'id': '7894164',
        'brand': 'recargas epayco',
        'category': 'Recargas',
        'price': '25000.00',
        'list': 'recargas epayco',
        'position': '3',
        "quantity": 1
    },
    {
        'name': 'recarga 40mil',
        'id': '7894164',
        'brand': 'recargas epayco',
        'category': 'Recargas',
        'price': '40000.00',
        'list': 'recargas epayco',
        'position': '4',
        "quantity": 1
    },
    {
        'name': 'recarga 50mil',
        'id': '7894164',
        'brand': 'recargas epayco',
        'category': 'Recargas',
        'price': '50000.00',
        'list': 'recargas epayco',
        'position': '5',
        "quantity": 1
    },
    {
        'name': 'recarga custom',
        'id': '7894164',
        'brand': 'recargas epayco',
        'category': 'Recargas',
        'price': '0',
        'list': 'recargas epayco',
        'position': '6',
        "quantity": 1
    }
];

var tagManagerArgs = (params) => {

    let dataLayer = {
        'event': 'trackEvent', // Nombre del evento que se utiliza en GTM.
        'eventCategory': params.eventCategory,
        'eventAction': params.eventAction,
        'eventLabel': params.valor // Path y nombre de la página virtual (String). Requirido.
    }

    TagManager.setNewTagManager(dataLayer)
};

var tagManagerView = (params) => {
    let dataLayer = {
        'event': 'trackPageview', // Nombre del evento que se utiliza en GTM.
        'pageName': params.pageName // Path y nombre de la página virtual (String). Requirido.
    }
    TagManager.setNewTagManager(dataLayer)
};

var valoresRecarga = ["3000", "6000", "15000", "25000", "40000", "50000"];

var tagManagerAddCart = (params) => {
    let name = 'recarga ' + params.price;
    let precio = params.price + '.00'
    let pos = valoresRecarga.includes(params.price) ? productos.find(item => item.price === precio).position : 6;
    let price = params.price + '.00'
    let dataLayer = {
        'productos': [{
            'name': name,
            'id': '7894164',
            'brand': 'recargas epayco',
            'category': 'Recargas',
            'price': price,
            'list': 'recargas epayco',
            'position': pos,
            'quantity': 1
        }],
        'event': 'trackaddtocart',
    }
    TagManager.setNewTagManager(dataLayer)
};


const tagManagerCart = (transaction) => {

    let dataLayer = {
        'compra': {
            'id': transaction.detail.x_ref_payco,
            'revenue': `${transaction.detail.x_amount}.00`
        },
        'cliente': {
            'email': transaction.customer.email,
            'movil': transaction.customer.mobilePhone
        },
        'transaccion': {
            'estado': transaction.detail.x_transaction_state
        },
        'productos': [{
            'name': `recarga ${transaction.detail.x_amount}`,
            'id': transaction.detail.x_extra1,
            'brand': 'Recargas-epayco',
            'category': 'Recargas',
            'price': `${transaction.detail.x_amount}.00`,
            'list': 'recargas epayco',
            'variant': `${transaction.detail.x_franchise}`,
            'coupon': '7894164',
            'position': '0',
            'quantity': 1
        }],
        'id': transaction.detail.x_ref_payco,
        'event': 'trackTransaction',
    }
    TagManager.setNewTagManager(dataLayer)
    
};

const ci360Event = (data) =>{
    window.ci360("send", {
        "eventName": "custom",
        "event":"custom",
        "customGroupName":"custom",
        "customRevenue":"0",
        "apiEventKey": "finalizacion-compra-recargas",
        "linea":data.line_pay,
        "referencia": data.id_invoice,
        "referencia_epayco": data.ref_payco,
        "valor": data.amount,
        "estado_transaccion": data.transaction_state
    });
}

const cookies = new Cookies();
const moment = require('moment');

const MantenimientoPage = () => {
    return(
        <div className={"wc hc f02 pa bgb"} style={{zIndex:"999"}}>
            <img src="/img/isologo.png" alt=""/>

            <div className="wc text-center py-4">
                <h2 className={"cb"}>Página en Mantenimiento.</h2>
            </div>
        </div>
    )
}



class App extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isLogin:true,
            loading: false,
            loadingButton: false,
            modal: {
                type: "recarga",
                open: false,
            },
            recargaData: {
                number: "",
                amount: "",
            },
            responseData: {},
            conteo: 5,
            realizandoRecarga: true,
            recargaExitosa: false,
            currentCount: 10,
            intervalId: "",
            intentos: 0,
            ref_transactio: "",
            novum: false,
            urlcallback: "",
            postMessage: false,
            banner: "A", // A si es el normal B si es el de fin de semana
            valorRecargaUrl: '',
            cargando: true,
            loadingWidth: 0,
            megas:false,
            megas01:true,
            timeResponse : {},
            mantenimiento:false,
            paramTime:'',
            request_id: "",
            modalCC:false,
            raspa_gana_url: ""
        };

        this.handleModal = this.handleModal.bind(this);
        this.conteo = this.conteo.bind(this);
        this.submitData = this.submitData.bind(this);
        this.openCkeckout = this.openCkeckout.bind(this);
        this.aceptPromo = this.aceptPromo.bind(this);
        this.getColorTrx = this.getColorTrx.bind(this);
        this.contador = this.contador.bind(this);
        this.verfifyRecharge = this.verfifyRecharge.bind(this);
        this.changeState = this.changeState.bind(this);
        this.getDataServer = this.getDataServer.bind(this);
        this.openModal2 = this.openModal2.bind(this);

        this.ePayco = undefined;
    }

    componentDidMount() {
        this.validateRoutes()
        this.validatePageMaintenance()
        const script = document.createElement("script");
        // let cambiaestado = () => this.setState({
        //     cargando: false
        // })

        if (!this.ePayco) {
            this.ePayco = window.ePayco;
        }
        // setTimeout(function () {
        //     cambiaestado();
        // }, 2000)
       if( cookies.get('valor_url')!== undefined || cookies.get('valor_url')!== null){
           this.setState({valorRecargaUrl: cookies.get('valor_url')})
       }

    }

    validatePageMaintenance(){
        var format = 'YYYY-MM-DD hh:mm:ss a'
        var hora = moment().format(format)
        var time = moment(hora,format);
        var beforeTime = moment('2020-07-20 01:00:00:00 pm', format);
        var afterTime = moment('2020-07-20 07:40:00:00 pm', format);
        if (time.isBetween(beforeTime, afterTime)) {
            //Mostrar ventana de mantenimiento
            this.setState({
                mantenimiento:true
            })
        }

    }

    componentWillMount() {
        this.validatePageMaintenance()
        //Peticion al servicio de Time
        let getVariableParam = (parametro) => {
            var results = new RegExp('[\?&]' + parametro + '=([^&#]*)').exec(window.location.href);
            if (results == null){
                return null;
            }
            else {
                return decodeURI(results[1]) || 0;
            }
        }
        //se obtienen los parametros para redireccion en caso de smartlink
        let valor_url = getVariableParam("valor_recarga");
        let numero_url = getVariableParam("numero");
        let canal_url = getVariableParam("canal");
        let novumCookie = getVariableParam("novum");
        let novumUrlcallBack = getVariableParam("urlcallback");

        if(valor_url != null){
            cookies.set('valor_url', valor_url)
        }
        if(numero_url != null){
            cookies.set('numero_url', numero_url)
        }
        if(canal_url != null){
            cookies.set('canal_url', canal_url)
        }
        if(novumCookie != null){
            cookies.set('novum', novumCookie)
        }
        if(novumUrlcallBack != null){
            cookies.set('urlcallback', novumUrlcallBack)
        }else{
            cookies.set('urlcallback', "aHR0cHM6Ly93ZWIubW92aXN0YXIuY29tLmNvL2NvbnN1bXB0aW9ucw==")
        }

        let cookieLogin = cookies.get('login') || ""
        if(cookieLogin !== ""){
            this.setState({isLogin: true})
        }
        let url = config.urlTimeAuth
        let time = sessionStorage.getItem('TIME')
        if(window.location.pathname !== '/resumen'){
            console.log("dentro de time", time)
            if(!time){
                console.log("estamos consultandoooo a la url de autenticacion de time");
                let search = window.location.search;
                search = search.substring(1);
                search = search.replace(/&/g,'--')
                search = search.replace(/=/g,'..')

                this.setState({search:search}, () => {
                    console.log("estamos aqui??", search);
                });
                sessionStorage.setItem('TIME', true);

                if(config.ENVIRONMENT === 'production'){
                    window.location.href = url + search
                }
            }
        }


        if (window.location.search !== "") {
            let novum = cookies.get('novum');
            if (novum === 'true') {
                this.setState({
                    novum: true,
                    urlcallback: this.getParamUrl('urlcallback') || cookies.get('urlcallback') || '',
                    postMessage: true,
                    valorRecargaUrl: this.getParamUrl('valor_recarga') || cookies.get('valor_url') || ''
                }, () => {
                    // window.location.href = url + window.location.search
                });
                // window.location.href = url + search
            }
        }
        var day = moment().format('YYYY-MM-DD');
        var arrDays = [
            '2019-06-01',
            '2019-06-02',
            '2019-06-03',
            '2019-06-07',
            '2019-06-08',
            '2019-06-09',
            '2019-06-14',
            '2019-06-15',
            '2019-06-21',
            '2019-06-22',
            '2019-06-23',
            '2019-06-24',
            '2019-06-28',
            '2019-06-29',
            '2019-06-30',
        ];

        var isDate = arrDays.filter(function (number) {
            return number == day;
        });
        if (isDate.length > 0) {
            this.setState({banner: 'B'})
        }
    }

    validateRoutes(){
        
        let path = window.location.pathname;
        if(this.getParamUrl('time')){
            sessionStorage.setItem('code', this.getParamUrl('time'))
            let code = this.getParamUrl('time')
            serviceApi({code: code, redirect_uri: `${config.url}/time_redirect`},`${config.url}/time_token`, 'POST')
                .then((response)=>{
                    console.log('RESPUESTA DE TOKEN:::', response)
                    this.setState({
                        timeResponse: response.data,
                        paramTime:response.data.number
                    })
                })
                .catch((err)=>{
                    console.log('ERROR TOKEN :::', err)
                })

        }
        else{
            if(this.getParamUrl('reason')){
                window.location.assign(config.urlFront)
            }
        }

        // //Request de prueba
        // serviceApi({code: 'code', redirect_uri: `${config.url}/time_redirect`},`${config.url}/time_token`, 'POST')
        // .then((response)=>{
        //     console.log('RESPUESTA DE TOKEN:::', response)
        // })
        // .catch((err)=>{
        //     console.log('ERROR TOKEN :::', err)
        // })

    }

    getParamUrl(name) {
        var results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(window.location.href);
        if (results == null){
            return null;
        }
        else {
            return decodeURI(results[1]) || 0;
        }

    }

    changeState() {
        let parametros = {
            realizandoRecarga: this.state.realizandoRecarga,
            recargaExitosa: this.state.recargaExitosa
        }

        return parametros
    }

    verfifyRecharge(ref_payco) {
        return (new Promise((resolve, reject) => {
            if (this.state.intentos <= 3) {
                serviceApi('', `${config.url}/validar_estado_recarga?id_invoice=${ref_payco}`, 'GET')
                    .then(response => {
                        if (response.state) {
                            this.setState({
                                recargaExitosa: response.state,
                                realizandoRecarga: false
                            })
                            resolve(response)
                        } else {
                            this.setState({
                                realizandoRecarga: true,
                                intentos: this.state.intentos + 1
                            })
                            // alert("llamo al modal")
                            this.handleModal(true, "tryagain")
                        }

                    }).catch(error => {
                    // console.log("error", error)

                    resolve(error);
                })
            } else {
                this.setState({
                    realizandoRecarga: false
                })
                this.handleModal(true, "recargafallida")
            }
        }));
    }

    handleModal(cond, type) {
        if (cond) {
            this.setState({
                modal: Object.assign({}, this.state.modal, {open: cond})
            });
        }
            try {
                if (type.split('_')[0] === 'timeout') {
                    if (type.split('_')[1] === 'volver') {
                        tagManagerArgs({
                            eventCategory: 'recarga en linea',
                            eventAction: 'timeout',
                            valor: 'volver'
                        })
                    }
                }
            }
            catch (error) {
            }
        if (type) {
            this.setState({
                modal: {
                    type: type,
                    open: cond
                }
            });
            if (type === "tryagain") {
                this.contador()
            }
            if (type === 'timeout') {
                tagManagerView({
                    pageName: '/recarga_en_linea/timeout'
                })
            }
        } else {
            this.setState({
                modal: Object.assign({}, this.state.modal,
                    {open: !this.state.modal.open})
            });
        }
    }


    conteo(action) {
        this.setState({
            conteo: 5,
        });
        let valor = this.state.conteo;
        let contador = setInterval(() => {
            if (valor === 0) {
                clearInterval(contador);
                action()
            }
            this.setState({
                conteo: valor--,
            })
        }, 1000)
    }

    showViewMegas(){
        let changeViewMegas = (value) => this.setState({
            megas01: !value

        })
        let timeAwait = this.state.timeResponse.timeAwait
        // console.log("tiempo timeAwait ::::", timeAwait)
        this.setState({
            megas: true,
            megas01: true
        }, () => {
            let megas = this.state.megas01
            // console.log("linea 498 requestID", this.state.request_id)
            contadorIntentos = contadorIntentos - 1;
            if(contadorIntentos >= 0){
                // console.log(contadorIntentos)
                this.state.request_id != "" ? this.verifyStatusMegas(this.state.request_id) : console.log("no existe el request_id");
            }
            // console.log('EN EL METODO', megas)
            setTimeout(function () {
                // console.log('DENTRO DEL TIMEOUT')
                changeViewMegas(megas)
            }, timeAwait * 1000)
        })

    }

    validateMegas(newValue){
        if(this.state.timeResponse.IsOkMegas){
            // console.log("estamos probando si aprovisionamos o no")
            this.aproviosnarMegas()
        }
        else{
            // console.log("aqui esta itentnado abrir el checkout por alguna razon ")
            this.openCkeckout(newValue)
            // this.state.recargaData.amount
        }
    }

    async submitData(data) {
            data.token = config.token
            //Verificar si existe data en TIME
            this.setState({loadingButton:true, recargaData: data})
            let time = this.state.timeResponse;
            if(this.state.timeResponse){
                if(data.number === time.number){
                    data.isMegas = true;
                    data.subscriberInformationItem = time.getSuscriberInfo
                }
            }
            if(this.state.mantenimiento === false){
                serviceApi(data, `${config.url}/validate_number`, 'POST')
                    .then(response => {
                        //console.log('Respuesta validate number: ', response)
                        this.setState({
                            loading        : false,
                            loadingButton  : false
                        });

                        if (response.success) {
                            tagManagerAddCart({price: data.amount})

                            if (data.amount < 30000) {
                                tagManagerView({
                                    pageName: '/recarga_en_linea/bono_datos'
                                })
                                this.setState({
                                    responseData: response,
                                    modal: {
                                        type: "promo",
                                        open: true
                                    }
                                });
                            }
                            else{
                                tagManagerView({
                                    pageName: '/recarga_en_linea/bono'
                                })
                                this.setState({
                                    recargaData: data,
                                    responseData: response,
                                    valorRecargaUrl: this.getParamUrl('valor_recarga') || cookies.get('valor_url'),
                                    modal: {
                                        type: "recarga",
                                        open: true
                                    }
                                });
                                this.conteo(() => {
                                    this.handleModal(false);
                                    //Validar para aplicación TIME
                                    if(time.IsOkMegas){
                                        this.aproviosnarMegas()
                                    }
                                    else{
                                        console.log(data,"::::: data")
                                        this.openCkeckout(this.state.recargaData.amount)
                                    }

                                })
                            }

                        } else {
                            this.setState({
                                recargaData: data,
                                responseData: response,
                            })
                        }

                    }).catch(error => {
                    //console.log("Aquí Error Submit: ", error)
                })
            }

    }

    aproviosnarMegas(){
        // console.log("entramos a aprovisionar las megas:::::::", this.state.timeResponse.number)
        //Mostrar view de aprovicionamiento dos megas
        this.showViewMegas();
        let data = {
            numero : this.state.timeResponse.number
        };
        serviceApi(data, `${config.url}/setbalance`, 'POST')
            .then((response)=>{
                // console.log('RESPUESTA DE APROVISIONAMIENTO::::', response)
                let requestId = response.data.request_id;
                this.setState({
                    request_id: requestId
                });
                response.data.request_id ? this.verifyStatusMegas(requestId)
                    : this.openCkeckout(this.state.recargaData.amount)
            }).catch((err)=>{

            // console.log('ERROR EN LA RESPUESTA DE APROVISIONAMIENTO:::', err)
        })
    }

    verifyStatusMegas(requestId){
        // console.log("------------------------------ VerifyStatusMeegas ------------------------")
        let data = {
            numero : this.state.timeResponse.number,
            requestId : requestId
        };
        serviceApi(data, `${config.url}/verifystatus`, 'POST')
            .then((response)=>{
                // console.log('RESPUESTA ESTADO DE LA TRANSACCION::::', response.data)
                if(response.status){
                    // console.log("---------se va a abrir el checkout------------------")
                    this.setState({
                        paramTime: `2MB, ${this.state.paramTime}`
                    }, ()=>{
                        this.openCkeckout(this.state.recargaData.amount)
                    } )
                }
                else{
                    this.openCkeckout(this.state.recargaData.amount)
                    // console.log("---------vamos a validar el aprovisionamiento de las 2mg------------------")
                    // if(contadorVerify >= 0){
                    //     contadorVerify = contadorVerify - 1
                    //     this.verifyStatusMegas(requestId)
                    // }
                }
            }).catch((err)=>{
            console.log('ERROR EN LA VERIFICACION DE ESTADO:::', err)
        })
    }

    aceptPromo(promo) {
        let newvalue = promo.replace("$", "").replace(",", "");
        tagManagerArgs({
            valor: "Quiero mas megas",
            eventAction: 'bono megas gratis',
            eventCategory: 'recarga en linea'
        });
        this.setState({
                recargaData: Object.assign({}, this.state.recargaData, {amount: newvalue}),
                modal: Object.assign({}, this.state.modal, {open: false})
            },
            this.validateMegas(newvalue))

    }

    openCkeckout(amount) {
        // console.log("llega hasta el checkout")
        let dataCheckout = {};
        let changeViewMegas = () => this.setState({
            megas: false
        })
        let data = {...this.state.recargaData, ...this.state.responseData};
        let numero = data.number;
        let canalEnvio
        if(cookies.get('canal_url') != undefined){
            canalEnvio = cookies.get('canal_url')
        }else{
            canalEnvio = 'web'
        }

        if (numero !== '' && numero.length === 10) {
            var handler = this.ePayco.checkout.configure({
                key: '5d826960a9d14d019f70284f55ef639b',
                test: false
            });

            handler.onCloseModal = function () {
                changeViewMegas()
            };

            var elements = this.ePayco.elements.create('general', {
                modules: {
                    countryBarEnabled: false,
                    duesSelected: false
                }
            })


            dataCheckout = {
                //Parametros compra (obligatorio)
                name: "recarga",
                description: "Recarga Celular " + numero,
                invoice: data.id_invoice,
                currency: "cop",
                amount: amount,
                tax_base: "0",
                tax: "0",
                country: "co",
                lang: "es",

                //Onpage="false" - Standard="true"
                external: "false",

                //Atributos opcionales
                extra1: numero,
                extra2: canalEnvio,
                extra5: canalEnvio,
                extra8: data.cuenta,
                extra9: this.state.paramTime || '',
                // extra3: document.getElementById('numDocument').value,
                response: `${config.urlFront}/resumen`,
                confirmation: `${config.urlFront}/transaction_confirmation`,

                //Atributos cliente
                name_billing: '',
                address_billing: '',
                type_doc_billing: '',
                mobilephone_billing: '',
                number_doc_billing: '',
                email_billing: '',
                force_response: true
            };

            if (this.state.novum) {
                //dataCheckout.movil_url = "https://msecure.epayco.io",
                dataCheckout.movil_url = "https://msecure.epayco.co"
                dataCheckout.response = `${config.urlFront}/resumen?novum=true&urlcallback=${cookies.get('urlcallback')}`
            }
            handler.onLoadCheckout = function () {
                cookies.remove('valor_url')
                // cookies.remove('canal_url')
                cookies.remove('numero_url')
            };
            handler.open(dataCheckout, elements);

        }
    }

    getColorTrx(transaction_state) {
        let color
        switch (transaction_state) {
            case 'Aceptada':
                return color = 'cv';
                break;
            case 'Fallida':
                return color = 'cr';
                break;
            case 'Rechazada':
                return color = 'cr';
                break;
            case 'Pendiente':
                return color = 'cy';
                break;
            default:
                return color = '';
                break;
        }
        return color
    }

    getDataServer(ref_payco, x_ref_payco, flagReload, x_cust_id_cliente) {

        this.setState({loading: true});
        serviceApi({
            ref_payco: ref_payco,
            x_ref_payco: x_ref_payco,
            x_cust_id_cliente: x_cust_id_cliente
        }, `${config.amonServices}/recargas/recharge/on/response`, 'POST')
            .then(async response => {

                this.setState({
                    loading: false,
                    realizandoRecarga: false,
                    ref_transaction: response.data.detail.x_ref_payco
                });
                if (response.success) {
                    this.setState({ cargando: false })
                    let epayco_transaction_data = response.data.detail;
                    let colorTrx = this.getColorTrx(epayco_transaction_data.x_transaction_state)
                    cookies.set('epaycoTransactionData', epayco_transaction_data);
                    let dataResponse = {
                        transaction_date: epayco_transaction_data.x_transaction_date,
                        customer_ip: epayco_transaction_data.x_customer_ip,
                        line_pay: epayco_transaction_data.x_extra1,
                        id_invoice: epayco_transaction_data.x_id_factura,
                        no_invoice: epayco_transaction_data.x_extra1,
                        transaction_state: epayco_transaction_data.x_transaction_state,
                        colorTrx: colorTrx,
                        ref_payco: epayco_transaction_data.x_ref_payco,
                        amount: epayco_transaction_data.x_amount,
                        response_reason_text: epayco_transaction_data.x_response_reason_text,
                        franchise: epayco_transaction_data.x_franchise,
                        email: epayco_transaction_data.x_customer_email,
                        //cus: epayco_transaction_data.x_transaction_id,
                        cus: epayco_transaction_data.x_approval_code
                    }

                    tagManagerCart(response.data);
                    egoiRender(response.data);
                    ci360Event(dataResponse)
                    this.setState({
                        loading: false,
                        responseData: dataResponse
                    })
                    // this.props.setResponse(response.paymentData);
                } else {
                    // this.setState({
                    //     errorServer:messageErr[response.error],
                    //     loading:false,
                    // });
                }

                if (this.state.responseData.transaction_state === "Aceptada") {

                    this.setState({
                        realizandoRecarga: false,
                        recargaExitosa: true
                    })

                }

            }).catch(error => {
            this.setState({
                showError: true,
                loading: false
            });
        });
    }

    //---------------------------------------
    // si hay que intentar recargar de nuevo
    //----------------------------------------
    contador() {
        var intervalId = setInterval(this.timer.bind(this), 1000);
        // store intervalId in the state so it can be accessed later:
        this.setState({intervalId: intervalId});
    }

    timer() {
        // setState method is used to update the state
        this.setState({currentCount: this.state.currentCount - 1},
            () => {
                if (this.state.currentCount === 0) {
                    clearInterval(this.state.intervalId)

                    this.setState({
                        intervalId: "",
                        modal: {
                            open: false,
                            type: ""
                        },
                        currentCount: 10,
                        intentos: this.state.intentos++
                    })

                    let verfyRecharge = this.verfifyRecharge(this.state.ref_transaction)


                }
            });

    }
    //=================

    openModal2(cond,type) {
        if (cond) {
            this.setState({
                modal2: Object.assign({}, {open: cond,type})
            })
        } else {
            this.setState({
                modal2: Object.assign({}, this.state.modal2, {open: !this.state.modal2.open})
            })
        }
    }

    render() {

        let amount = parseInt(this.state.recargaData.amount) || parseInt(this.getParamUrl('valor_recarga')) || parseInt(cookies.get('valor_url'));
        let typeM = this.state.modal.type;

        let megas = {
            "$3,000"  : "50 Megas",
            "$6,000"  : "200 Megas",
            "$10,000" : "500 Megas",
            "$15,000" : "500 megas",
            "$25,000" : "1 GB",
            "$30,000" : "1 GB",
        };

        let increase =
                amount  <  3000   ? "$3,000" :
                amount  >= 3000  && amount < 6000  ? "$6,000" :
                amount  >= 6000  && amount < 10000 ? "$10,000" :
                amount  >= 10000 && amount < 15000 ? "$15,000" :
                amount  >= 15000 && amount < 25000 ? "$25,000" :
                amount  >= 25000 && amount < 30000 ? "$30,000" : "";

        let icono =
            typeM === "recarga"        ? "happy.png" :
            typeM === "timeout"        ? "timeout.svg" :
            typeM === "tryagain"       ? "recargando.png" :
            typeM === "recargafallida" ? "clock.png" : "";


        return (
            <div className={`main-container ${this.state.mantenimiento && "desenfocar"}`}>

                {/*{this.state.mantenimiento && <MantenimientoPage/>}*/}


                <CSSTransition
                    in={this.state.cargando}
                    timeout={600}
                    classNames="fade"
                    unmountOnExit
                >
                    <Cargando novum={this.state.novum}/>
                </CSSTransition>


                <BrowserRouter>
                    <Fragment>
                        <Header loaction={location}/>
                        {/*para el ambiente de pruebas*/}
                        <Route exact path={"/login"} render={() =>
                            <Login
                                data={this.state.recargaData}
                            />
                        }/>

                        <Route exact path={"/"} render={() => (
                            this.state.isLogin ?
                                <Home
                                    submit={this.submitData}
                                    data={this.state.recargaData}
                                    responseData={this.state.responseData}
                                    loadingButton={this.state.loadingButton}
                                    banner={this.state.banner}
                                    productos={productos}
                                    valorRecarga={this.state.valorRecargaUrl}
                                    megas={this.state.megas}
                                    megas01={this.state.megas01}
                                    showViewMegas={()=> this.showViewMegas()}
                                    mantenimiento={this.state.mantenimiento}
                                />
                                :
                                <Redirect to="/login"/>
                        )}/>


                        <Route path={"/resumen"} render={() =>

                            <Response
                                realizandoRecarga={this.state.realizandoRecarga}
                                recargaExitosa={this.state.recargaExitosa}
                                modal={() => this.handleModal(true, "recargafallida")}
                                verfifyRecharge={this.verfifyRecharge}
                                getDataServer={this.getDataServer}
                                responseData={this.state.responseData}
                            />

                        } />

                        {/* ////  BOTÓN PARA HACER PRUEBAS CON EL MODAL

                        <button
                            onClick={()=>
                                this.setState({
                                modal:{open:true,type:"promo"
                            }})}
                         >
                         hola men
                         </button>



                        ////  BOTÓN PARA HACER PRUEBAS CON EL MODAL */}

                    </Fragment>



                </BrowserRouter>



                {/*
                     ESTOS SON LOS MODALES A ACTUALIZAR
                */}
                <ShowModal
                    show={this.state.modal.open}
                    // show={true}
                    reset={this.state.modal.type === "promo"}
                    className={"col-12 py-4 max350"}
                    onClose={() => {
                        (this.state.modal.type === 'recarga' ? tagManagerArgs({
                            valor: 'cerrar',
                            eventAction: 'bono megas gratis',
                            eventCategory: 'recarga en linea'
                        }) : (this.state.modal.type === 'timeout') ? (
                            tagManagerArgs({
                                valor: 'cerrar',
                                eventAction: 'timeout',
                                eventCategory: 'recarga en linea'
                            })
                        ) : tagManagerArgs({
                            valor: 'cerrar',
                            eventAction: 'bono megas gratis',
                            eventCategory: 'recarga en linea'
                        }));

                        this.handleModal();
                        this.setState({loadingButton: false})
                    }}
                    hideClose={
                        this.state.modal.type === "recarga" ||
                        this.state.modal.type === "tryagain"
                    }
                >
                    <div className={"wc"}>
                        {/* --------------------------------
                            icono
                        ------------------------------------*/}
                        <div className={"col-12 text-center"}>
                            <img src={`/img/iconos/${icono}`}
                                 alt=""
                                 className={"col-12 mx-auto"}
                                 style={{maxHeight: "90px",width:"auto"}}
                            />
                        </div>


                        {/* --------------------------------
                            contenido
                        ------------------------------------*/}

                        <ModalPromo
                        show={this.state.modal.type === "promo"}
                        value={
                            this.state.recargaData.amount ||
                            this.state.valorRecargaUrl ||
                            this.getParamUrl('valor_recarga') ||
                            cookies.get('valor_url')
                        }
                        close={()=>{
                            this.setState({
                                modal: {
                                    open: false, type: "promo"
                                }}
                            )
                        }}
                        valuePromo={increase}
                        // increase
                            megas={megas[increase]}
                            //megas[increase]
                        onClick1={()=>{
                            tagManagerArgs({
                                valor: `Recargar solo ${this.state.recargaData.amount}`,
                                eventAction: 'bono megas gratis',
                                eventCategory: 'recarga en linea'
                            });
                            this.validateMegas(this.state.recargaData.amount);
                            this.handleModal(false)
                        }}
                        onClick2={()=>{
                            this.aceptPromo(increase)
                        }}
                    />


                        {/*<div className={"text-center mb-4 dn"}>*/}
                        {/*    <p className={"mb-4 mt-3"}>Si recargas <b>${increase} te obsequiamos</b> <br/>*/}
                        {/*        <span className={"cv"}><b>{megas[increase]}</b> gratis</span>*/}
                        {/*    </p>*/}
                        {/*    <small className={"d-block"} style={{lineHeight: "1.5em"}}>Al seleccionar "Quiero más megas*/}
                        {/*        gratis" estás <br/> aceptando los <b>*/}
                        {/*            <a className={"cn"}*/}
                        {/*               href="https://www.movistar.co/documents/117302439/403688051/TC+Recargas+Mayo+2019+-+02052019.pdf/43d6b057-63ac-8038-c64f-597395dcfcbf?_ga=2.222921502.46033463.1556817947-1696387448.1556817947"*/}
                        {/*               target="_blank" rel="nofollow">Términos y Condiciones</a></b></small>*/}
                        {/*</div>*/}

                        {this.state.modal.type === "timeout" &&
                        <div className={"text-center mb-4"}>
                            <h2 className={"fl my-4"}>Te perdimos</h2><br/>
                            <p className={"mb-5"} style={{fontSize: '1.2em'}}>Llevas mucho tiempo fuera de <br/> línea, vuelve a realizar tu pedido </p>
                        </div>
                        }


                        {this.state.modal.type === "recarga" &&
                        <div className={"text-center"}>
                            <h2 className={"fl my-4"}>¡Felicidades!</h2>
                            <p className={"mb-0"} style={{fontSize: '1.2em'}}>Por esta recarga recibirás <br/></p>
                            <h1 className={"fwb cv"}>1 Giga GRATIS</h1>
                            <a style={{color: '#50535a', fontSize: '0.8em', margin: '30px 0', display: 'block'}} href={config.TerminosCondiciones}
                                target="_blank" rel="nofollow">Aplican términos y condiciones
                            </a>
                            <span className={"cv"}>Estás a un paso de recargar tu línea {this.state.conteo}</span>
                        </div>
                        }

                        {this.state.modal.type === "tryagain" &&
                        <Tryagain segundos={this.state.currentCount}
                                  action={this.handleModal}
                            //se llasma la funciona donde se valida la recarga
                            // modal={this.handleModal}
                        />
                        }

                        {this.state.modal.type === "recargafallida" &&
                        <div className="wc text-center">
                            <div className="wc text-center py-3">
                                <b style={{fontSize:"20px",color:colors.azul}} className={"fwb my-3"}>Recarga en proceso</b>
                            </div>
                            <p style={{lineHeight:"1.3em"}}>No te preocupes, en los próximos minutos tu recarga será aplicada y recbiras un SMS de confirmación</p>
                            <small style={{lineHeight:"1.3em",display:"block"}}>De lo contrario escríbenos al siguiente correo <span style={{color:colors.azul}}>servicio.cliente@payco.co</span>
                            </small>

                            <div className="wc pt-5">
                                <Btn
                                    type={true}
                                    onClick={()=>{
                                        this.setState({
                                        modal: {...this.state.modal,
                                        open:false,
                                        type:"recargafallida"
                                    }
                                    })
                                    }}
                                >
                                    Aceptar
                                </Btn>
                            </div>
                        </div>
                        }
                        {/* --------------------------------
                            Zona de Botones
                        ------------------------------------*/}

                        {this.state.modal.type === "promo" &&
                        <div className="wc dn">
                            <div className="cont-btn-t wc px-0">
                                <button className={"btn-b wc"}
                                        onClick={() => {
                                            tagManagerArgs({
                                                valor: `Recargar solo ${this.state.recargaData.amount}`,
                                                eventAction: 'bono megas gratis',
                                                eventCategory: 'recarga en linea'
                                            })
                                            this.validateMegas(this.state.recargaData.amount)
                                            this.handleModal(false)
                                        }}
                                >
                                    Recargar solo
                                    ${this.state.recargaData.amount || this.state.valorRecargaUrl || this.getParamUrl('valor_recarga') || cookies.get('valor_url')}
                                </button>
                            </div>
                            <div className="cont-btn-t wc mt-3 ">
                                <button className={"btn-t wc"}
                                        onClick={() => {
                                            this.aceptPromo(increase)
                                        }}
                                >
                                    Quiero más megas
                                </button>
                            </div>
                        </div>
                        }

                        {this.state.modal.type === "timeout" &&
                        <div className="wc">
                            <div className="cont-btn-t">
                                <button className="btn-t wc"
                                        onClick={() => this.handleModal(false, 'timeout_volver')}
                                >Volver
                                </button>
                            </div>
                        </div>
                        }
                    </div>



                </ShowModal>

                {/*
                    FIN MODALES A ACTUALIZAR
                */}
                <ShowModal
                    // show={this.state.modalCC}
                    show={this.state.modalCC}
                    reset
                >

                    {/*<ModalCC*/}
                    {/*    bono={"5000"}*/}
                    {/*    codigo={this.state.codigo_bono}*/}
                    {/*    onClose={() => this.setState({modalCC:false})}*/}
                    {/*    mainAction={() =>  window.location.href = ' https://www.fandango.lat/co'}*/}
                    {/*/>*/}
                    <ModalCC
                        onClose={() => this.setState({modalCC:false})}
                        mainAction={() =>  window.location.href = this.state.raspa_gana_url}
                    />
                </ShowModal>

                <ShowModal
                    // show={this.state.modalCC}
                    show={this.state.mantenimiento}
                    reset
                    masOscuro
                >
                    <div>
                        <MantenimientoPage/>
                    </div>
                </ShowModal>

            </div>

        );
    }
}

export default App;
