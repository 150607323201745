import React from "react";
import Slider from "react-slick";

const moment = require('moment');

let TDC = "https://www.movistar.co/documents/115573275/407525437/Vigentes+Oferta+Prepago+Octubre+01+de+2020.pdf/aa651073-1ec5-bd9b-5e1f-63f97d8ca8b3?_ga=2.185249900.495555083.1601301522-1954648765.1598554782&_gac=1.185932379.1601301630.Cj0KCQjwqrb7BRDlARIsACwGad6xZ0lH_P7roJ5JDrnOleASZ5vmRMOgi159b7hDYobYTwZXbh8HKKEaAvTbEALw_wcB"
let NAG = "https://nam02.safelinks.protection.outlook.com/?url=https%3A%2F%2Fmimovil.movistar.co%2Fprepago%2Fventas-paquetes%3F_ga%3D2.183961890.528877094.1597417062-986203269.1594995076&data=01%7C01%7Ckarina.serrano%40payco.co%7Cbec153c81d124915261b08d845e760fe%7Cb8ad6671941843ad968ba9dd0ed3eb62%7C0&sdata=jZP5EiaCd7eh9FD07gO%2FqTR6bEsj6emhsN062reaCIk%3D&reserved=0";
//const FIND        = [3,4,5,10,11,12,24,25,26,27].filter(item => item == moment().date())[0];

const DUPLICA     = [2,3,8,12,15,17,20,23,25,28].filter(item => item == moment().date())[0];
const EST         = [1,6,10,14,16,22,26,30].filter(item => item == moment().date())[0];
const TRIP        = [4,13,18,27,29].filter(item => item == moment().date())[0];
const PERC        = [5,7,9,11,19,21,24].filter(item => item == moment().date())[0];
/*
const DUPLICA     = [1].filter(item => item == moment().date())[0];
const EST         = [2].filter(item => item == moment().date())[0];
const TRIP        = [9].filter(item => item == moment().date())[0];
const PERC        = [4].filter(item => item == moment().date())[0];
*/

// Banner Temporales
// const TRIPTM        = [19].filter(item => item == moment().date())[0];
// const DUPLICATM     = [20,21].filter(item => item == moment().date())[0];

let getSettings = (bannerActivo) => {
    var settings = {
        dots: true,
        infinite: true,
        autoplay: true,
        speed: 800,
        slidesToShow: 1,
        slidesToScroll: 1,
        className: "myslider",
        slide: "section",
        adaptiveHeight: true,
        autoplaySpeed: 5000,
        initialSlide: bannerActivo
    };
    return settings;
};


var settings2 = {
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    autoplaySpeed: 5000
};


////////////////////////////////////////////////
// Banner en móvil  =============================

const RecargasDoblesM = () => {
    return (
        <div className={"wc "}>
            <img src="/img/banner/Noviembre2020/double/small.jpg" alt="" className={"imgr d-sm-none"}/>
            <img src="/img/banner/Noviembre2020/double/tablet.jpg" alt="" className={"imgr d-none d-sm-block"}/>
        </div>
    );
};

const FindesemanaM = () => {
    return (
        <div className={"wc "}>
            <img src="/img/banner/Julio2020/weekend/small.jpg" alt="" className={"imgr d-sm-none"}/>
            <img src="/img/banner/Julio2020/weekend/tablet.jpg" alt="" className={"imgr d-none d-sm-block"}/>
        </div>
    );
};

const Estructura = () => {
    return (
        <div className={"wc "}>
            <img src="/img/banner/Noviembre2020/structure/small.jpg" alt="" className={"imgr d-sm-none"}/>
            <img src="/img/banner/Noviembre2020/structure/tablet.jpg" alt="" className={"imgr d-none d-sm-block"}/>
        </div>
    );
};

const Triple = () => {
    return (
        <div className={"wc "}>
            <img src="/img/banner/Noviembre2020/triple/small.jpg" alt="" className={"imgr d-sm-none"}/>
            <img src="/img/banner/Noviembre2020/triple/tablet.jpg" alt="" className={"imgr d-none d-sm-block"}/>
        </div>
    )
};
// Banner Temporal
const ThreeRechargeTM = () => {
    return (
        <div className={"wc "}>
            <img src="/img/banner/Octubre2020/triple/small.jpg" alt="" className={"imgr d-sm-none"}/>
            <img src="/img/banner/Octubre2020/triple/tablet.jpg" alt="" className={"imgr d-none d-sm-block"}/>
        </div>
    )
};

const TwoRechargeTM = () => {
    return (
        <div className={"wc "}>
            <img src="/img/banner/Octubre2020/double/small.jpg" alt="" className={"imgr d-sm-none"}/>
            <img src="/img/banner/Octubre2020/double/tablet.jpg" alt="" className={"imgr d-none d-sm-block"}/>
        </div>
    );
};
// Fin Temporal
const PercentageM = () => {
    return (
        <div className={"wc "}>
            <img src="/img/banner/Noviembre2020/percentage/small.jpg" alt="" className={"imgr d-sm-none"}/>
            <img src="/img/banner/Noviembre2020/percentage/tablet.jpg" alt="" className={"imgr d-none d-sm-block"}/>
        </div>
    )
};

const ExtraBannerM = () => {
    return (
        <div className={"wc "}>
            <img src="/img/banner/Julio2020/turn-page/small.jpg" alt="" className={"imgr d-sm-none"}/>
            <img src="/img/banner/Julio2020/turn-page/tablet.jpg" alt="" className={"imgr d-none d-sm-block"}/>
        </div>
    )
};

const MovilAgost = () => {
    return (
        <div className={"wc position-relative"}>
            <a className={"enlace-banner"} target={"_blank"} href={TDC}></a>
            <img src="/img/banner/agosto2020/movil.jpg" alt="" className={"imgr d-sm-none"}/>
            <img src="/img/banner/agosto2020/tablet.jpg" alt="" className={"imgr d-none d-sm-block"}/>
        </div>
    )
};

export const BannerMovil = () => {
    return (
        <div className={"cont-slider-movil"}>
            <Slider {...settings2}>
                { TRIP && <Triple/> }
                {/* { TRIPTM && <ThreeRechargeTM/> } */}
                { DUPLICA && <RecargasDoblesM/>}
                {/* { DUPLICATM && <TwoRechargeTM/>} */}
                { EST && <Estructura/>}
                { PERC && <PercentageM/>}
                {/*<ExtraBannerM/>*/}
                {<MovilAgost/>}
            </Slider>
        </div>
    )
};


///////////////////////////////////////////////
// banners de escritorio  ======================

export const RecargasDobles= () => {
    return (
        <div className={"RD p-3 p-xl-4"}>
            <div className="texto pt-0">
                <p className={"cb label"}> EXCLUSIVO SITIO WEB O APP MI MOVISTAR </p>
                <h1 className={"at mensaje  mb-3"}>Solo por hoy duplicamo tus datos adicionales</h1>
                <div className="terminos ">
                    <a href={TDC}
                       target={"_blank"}>Aplican términos y condiciones.</a>
                </div>
            </div>
        </div>
    )
};

export const RecargasTriples = () => {
    return (
        <div className={"RT p-3 p-xl-4 "}>
            <div className="texto pt-0">
                <p className={"cb label"}>EXCLUSIVO SITIO WEB O APP MI MOVISTAR</p>
                <h1 className={"at mensaje  mb-3"}>Solo por hoy triplicamos tus datos adicionales</h1>
                <div className="terminos ">
                    <a href={TDC}
                       target={"_blank"}>Aplican términos y condiciones.</a>
                </div>
            </div>
        </div>
    )
};
// Banner Temporal

export const DThreeRechargeTM = () => {
    return (
        <div className={"RT TM p-3 p-xl-4"}>
            <div className="texto pt-0">
                <p className={"cb label"}>EXCLUSIVO SITIO WEB O APP MI MOVISTAR</p>
                <h1 className={"at mensaje  mb-3"}>Recarga en línea y te damos 3GB de una</h1>
                <div className="terminos ">
                    <a href={TDC}
                       target={"_blank"}>Aplican términos y condiciones.</a>
                </div>
            </div>
        </div>
    )
};

export const DTwoRechargeTM= () => {
    return (
        <div className={"RD TM p-3 p-xl-4"}>
            <div className="texto pt-0">
                <p className={"cb label"}>EXCLUSIVO SITIO WEB O APP MI MOVISTAR</p>
                <h1 className={"at mensaje  mb-3"}>Te sumamos el doble de datos adicionales gratis</h1>
                <div className="terminos ">
                    <a href={TDC}
                       target={"_blank"}>Aplican términos y condiciones.</a>
                </div>
            </div>
        </div>
    )
};
// Fin Temporal
export const  Estruct = () => {
    return (
        <div className={"ES p-3 p-xl-4 "}>
            <div className="texto pt-0">
                <p className={"cb label"}>EXCLUSIVO SITIO WEB O APP MI MOVISTAR</p>
                <h1 className={"at mensaje  mb-3"}>Te damos más datos con cada recarga en línea</h1>
                <div className="terminos">
                    <a href={TDC}
                       target={"_blank"}>Aplican términos y condiciones.</a>
                </div>
            </div>
        </div>
    )
};

export const  Percentage = () => {
    return (
        <div className={"PE p-3 p-xl-4 "}>
            <div className="texto pt-0">
                <p className={"cb label"}>EXCLUSIVO SITIO WEB O APP MI MOVISTAR</p>
                <h1 className={"at mensaje  mb-3"}>Solo hoy te damos hasta 1.5 GB gratis</h1>
                <div className="terminos">
                    <a href={TDC} target={"_blank"}>Aplican términos y condiciones</a>
                </div>
            </div>
        </div>
    )
};




export const  FinDeSemana = () => {
    return (
        <div className={"weekend p-3 p-xl-4 "}>
            <div className="texto pt-0">
                <p className={"cb label"}>EXCLUSIVO RECARGAS EN LÍNEA</p>
                <h1 className={"at mensaje  mb-3"}>¡Recárgate de <br/>Megas gratis <br/>el finde!</h1>
                <div className="terminos">
                    <a href={TDC}
                       target={"_blank"}>Aplican términos y condiciones.</a>
                </div>
            </div>
        </div>
    )
};

export const  ExtraBanner = () => {
    return (
        <div className={"PP p-3 p-xl-4 "}>
            <div className="texto pt-0">
                <div className="terminos">
                    <a href={TDC}
                       target={"_blank"}>Aplican términos y condiciones.</a>
                </div>
            </div>
        </div>
    )
};

export const  NuevoAgosto = () => {
    return (
        <div className={"NA EX p-3 p-xl-4 position-relative"}>
            <a className={"enlace-banner"}
               target={"_blank"}
               href={NAG}>
            </a>
            <div className="texto pt-0">
                <p className={"cb label"}>EXCLUSIVO RECARGAS EN LÍNEA DESDE $15.000</p>
                <h1 className={"at mensaje  mb-3"}>Ya puedes comprar tu paquete favorito en línea</h1>
                <div className="terminos">
                    <a href={TDC}
                       target={"_blank"}>Aplican términos y condiciones.</a>
                </div>
            </div>
        </div>
    )
};

const Banner = (props) => {

    let bannerActivo = props.bannerActivo;
    return (
        <div className={"wc hc"}>
            <Slider {...getSettings(bannerActivo)}>
                { TRIP && <RecargasTriples/> }
                {/* { TRIPTM && <DThreeRechargeTM/> } */}
                { DUPLICA && <RecargasDobles/>}
                {/* { DUPLICATM && <DTwoRechargeTM/>} */}
                { EST && <Estruct/>}
                { PERC && <Percentage/>}
                {/*<ExtraBanner/>*/}
                {<NuevoAgosto/>}
            </Slider>
        </div>
    )
};
export default Banner;





