import React from "react";
import styled from "@emotion/styled";


export const colors = {
    azul:"#00a9e0",
    verde:"#5bc500"
};

const BtnS =styled.div`
    
    height:auto;
    width:100%;
    min-height:35px;
    
    button{
        min-height:35px;
        border-radius:6px;
        color:white;
        width:100%;
        cursor:pointer;
        background:${props => props.color};
        text-align:${props => props.align};
    } 
  
`;

export const Btn = ({text, children, onClick, disabled,color,type})=>{
    return(
        <BtnS
            color={type ? colors.azul : colors.verde}

        >
            <button
                onClick={onClick}
                disabled={disabled}
                className={"f02"}
            >
               <span className={"mb-1"}> {children ? children :  {text}}</span>
            </button>
        </BtnS>
    )
};
