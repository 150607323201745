import React,{Fragment} from "react";
import {Collapse} from 'react-collapse';
import Cleave from 'cleave.js/react';
// import Select from 'react-select';
import $ from "jquery";


const CampoIcon = props => {

    let {
        value = "",
        name = "",
        options = {},
        placeholder= "",
        onChange=null,
        className = "",
        field=null,
        icon="",
        error = "",
        checked,
        showError=false,
        id="",
        type ="tel"
    } = props;

    let showChulo = (event) => {

        if($(event.target).is(":checked")){
            $(event.target).next(".chulo").addClass("chuleado")

        }else{
            $(event.target).next(".chulo").removeClass("chuleado")
        }

    };

    return (
        <div className={`campo-icon ${className}`} onClick={(e) => e.stopPropagation()}>

            {icon !== '' &&
            <span className    =  {` ${props.showError && !field ?  "error-icon" : ""} ${props.type === "checkbox" ? "dn" : ""} icon` }>
                <i className={` fa fa-${icon}`} style={{fontSize:"28px"}}></i>
            </span> }

            {props.type === "cleave" &&

            <Cleave
                numericOnly = {true}
                id={id}
                type = {'tel'}
                autoComplete=  {"off"}
                value       =  { value }
                name        =  { name }
                options     =  { options }
                placeholder =  { placeholder }
                onChange    =  { onChange }
                className    =  {` ${props.showError && !field ?  "error-input" : ""} ${icon !== "" ?  "icon-padding" : "pl-2"}` }
            />



            }

            {props.type === ("" || undefined) &&
            <input
                defaultValue=  { value }
                name        =  { name }
                options     =  { options }
                placeholder =  { placeholder }
                onChange    =  { onChange }
                className   = {` ${props.showError && !field ?  "error-input" : ""} ${icon !== "" ?  "icon-padding" : "pl-3"}` }
                type        = {'text'}
            />
            }

            {/*{props.type === "select"   &&*/}
                {/*<Fragment>*/}

                {/*<Select classNamePrefix={`${props.showError ? "select-error": "react-select"}`}*/}
                        {/*placeholder={props.placeholder}*/}
                        {/*options={props.options}*/}
                        {/*className={`mt-3`}*/}
                        {/*name={"nPrefijo"}*/}
                        {/*onChange={ (newValue) =>{*/}
                            {/*if(newValue !== null){*/}
                                {/*console.log(newValue)*/}
                                {/*props.onChange(event,newValue.value)*/}
                            {/*}*/}
                        {/*}}*/}
                        {/*isClearable={true}*/}
                        {/*isSearchable={true}*/}
                        {/*maxMenuHeight={150}*/}
                {/*/>*/}

                {/*</Fragment>*/}

            {/*}*/}

            {props.type === "checkbox"  &&
            
                <div className={"cont-chulo"} onClick={(e) => e.stopPropagation()}>
                    <input type="checkbox"
                           id={props.id}
                           checked={checked}
                           value       =  { value }
                           name        =  { name}
                           onChange    =  { (event) => {

                               onChange(event);
                               // showChulo(event)
                               // console.log(event.target.checked)
                           } }

                    />
                    <div className={`chulo imgb ${checked ? "chuleado" : "" }`}></div>
                </div>
                
            }

            <Collapse isOpened={showError}>
                <small className={"error"}>{props.error}</small>
            </Collapse>
        </div>
    )
}

export default CampoIcon;