import React from "react";
import { withRouter } from "react-router";

const Header = props => {
    let {location} = props;

    return (
        <header>
            <div className={`col-xl-10 m-xl-auto py-2 py-md-3 f01 jcsb ${location.pathname === "/"? "d-none" :""} d-md-flex`}  >
                <div className="logo-movistar col-md-4">
                <a href={'https://www.movistar.co/'}>
                    <img src="./img/logo.png" alt="Logo movistar" className={"imgr"}/>
                </a>
                </div>
                { props.location.pathname !== "/resumen" &&
                <div className="col-6 text-right f01 jcfe">
                        <p className={"mb-0 mr-3"}>Pagos Procesados por </p>
                    <img src="/img/epayco.png" alt="" style={{maxHeight:"30px"}}/>
                </div>}
            </div>
        </header>
    )
}

export default withRouter(Header);