import React from "react";


const styles = {
    position : "absolute",
    top      : "0",
    left     : "0",
    backgroundColor: "white",
    height   : "100%",
    width    : "100%",
    zIndex   : 9999,
}


const Progress = () => {
    return(
        <div className={"text-center cg f02 "}>
            <img src="/img/isologo.png" alt="" width={"auto"} height={"30px"} className={"mb-4"}/>
            <small className={"d-block mb-3"}>CARGANDO...</small>
            <div className="bala01"></div>
            <div className="bala02"></div>
            <div className="bala03"></div>
        </div>
    )
}

const Progress2 = ({novum}) => {
    return(
        <div className={"f02 wc hc "}>
            {novum}
            <img src="/img/Spinner200.gif" alt="" width={"80px"} height={"auto"}/>
            <p className={"mv-0"}>Espera mientras cargamos todo</p>
        </div>
    )
}


const Cargando = ( {novum}) =>{

    return(
        <div style={styles} className={"f02 "}>
            {novum ?
                <Progress2/>
                :
                <Progress/>
            }
        </div>
    )
}

export default Cargando;