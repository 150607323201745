import React from 'react';
import ReactDOM from 'react-dom';
import App from './app/components/App';
import registerServiceWorker, {unregister} from './registerServiceWorker';
import TagManager from './app/Helpers/tagManagerHelper'
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

TagManager.initializeTagManager();

//initialice hotjar params [hjid, hjsv]
//hotjar.initialize(707603, 6);
//Sentry Config
Sentry.init({
    dsn: 'https://69e2631a949c4c1aa0c1706ee297e146@o332279.ingest.sentry.io/1884321',
    integrations: [
        new Integrations.BrowserTracing(),
    ],
    ignoreErrors: ['google_tag_manager.dataLayer.get is not a function'],
    ignoreUrls: ['https://www.googletagmanager.com']
});
ReactDOM.render(<App />, document.getElementById('root'));
//registerServiceWorker();
unregister();