import React, {Component} from "react";
import CampoIcon from "../campo";
import SimpleReactValidator from "simple-react-validator";
import Metodos from "../sections/metodos";
import getUrlVars from "../helpers/urlparameters";
import {BannerMovil} from "../sections/banner";
import TagManager from '../../../Helpers/tagManagerHelper';
import Cookies from 'universal-cookie';
const cookies = new Cookies();





const Btnmonto = props => {

    let {monto, active, action} = props;

    return (
        <div className={`btn-amount  ${active ? "active" : ""}`}>
            <img src="/img/recuadro.png" alt="" className={"imgr"}/>
            <button
                disabled  = {props.disabled}
                value     = {props.value}
                type      = {"button"}
                className = {"f02"}
                onClick   = {(e) => action(e, "amount")}
            >
                ${monto}
            </button>
        </div>
    )
};

var tagManagerArgs = (params) => {
    let  dataLayer ={
        'event': 'trackEvent', // Nombre del evento que se utiliza en GTM.
        'eventCategory' : params.eventCategory,
        'eventAction': params.eventAction,
        'eventLabel': params.valor // Path y nombre de la página virtual (String). Requirido.
    }
    TagManager.setNewTagManager(dataLayer);
};

let trackProdClick = (name, value, position)=>{
    let dataLayer = {
        'productos': [{
            "name": name,
            'id': '7894164',
            'brand': 'Recargas-epayco',
            'category': 'Recargas',
            'price': value + '.00',
            'list' : 'recargas epayco',
            'position': position ,
            'quantity': 1
            }],
            'event': 'trackProdClick'
    };
    TagManager.setNewTagManager(dataLayer);
};
class Formulario extends Component {

    constructor(props) {
        super(props);

        this.state = {
            amount: "",
            number: "",
            loading:false,
            error : false,
            messageError: "",
            otroValor:"",
            recargaEvent:{
                event:'trackEvent',
                eventAction:'valor recarga',
                eventCategory:'recarga en linea',
                eventLabel:'20000'
            },
            customIndicator: false
        };
        this.setNumber = this.setNumber.bind(this);
        this.setValue = this.setValue.bind(this);
        this.submitForm = this.submitForm.bind(this);


        this.validator = new SimpleReactValidator({
            messages: {
                between: 'Número invalido.',
                required: "Debe ingresar un :attribute",
            },
            validators: {
                monto: {
                    message: 'El :attribute debe ser mínimo de $3,000 y máximo $100,000',
                    rule: () => {
                        if (this.state.amount < 3000 || this.state.amount > 100000) {
                            return false;
                        } else {
                            return true;
                        }
                    },
                },
                numero: {
                    message: 'Número no válido',
                    rule: () => {
                        if (this.state.number.length !== 10) {
                            return false;
                        } else {
                            return true;
                        }
                    },
                }
            }
        });
    }

    setNumber(event, name) {
        event.preventDefault();
        let value = event.target.value;
        //Ejecutar datalayer de click producto
        let nameProduct = `recarga ${value}`;

        let position = this.props.productos.find(item => item.price === value + '.00').position;
        trackProdClick(nameProduct, value, position)
        this.setState({
            [name]: value,
            otroValor:"",
            recargaEvent:{
                event:'trackEvent',
                eventAction:'valor recarga',
                eventCategory:'recarga en linea',
                eventLabel: value
            },
            customIndicator:false
        })
    }

    setValue(e) {
        let name  = e.target.name;
        let value = e.target.rawValue;
        let id    = e.target.id;
        if(id === 'otroValor'){
            this.setState({
                customIndicator:true,
                otroValor: value
            })
        }
        if (name === "amount") {
            if (value >= 3000 && value < 100000) {
                this.setState({
                    [name]: value,
                    error: Object.assign({}, this.state.error, {[name]: ""}),
                    touched: Object.assign({}, this.state.touched, {[name]: true}),
                    otroValor:value
                })
            } else {
                this.setState({
                    [name]: value

                })
            }
        } else if (name === "number") {

            if (value.length === 10) {
                this.setState({
                    [name]: value,
                    error: false,
                    messageError: ""
                })
            } else {
                this.setState({
                    [name]: value,
                    error:false
                })
            }
        }
    }

    submitForm(e) {
        e.preventDefault();
        this.setState({ loading:true });
        let data = {
            number:this.state.number,
            amount:this.state.amount
        };
        if(this.state.otroValor){
            trackProdClick('recarga custom', this.state.otroValor, 6);
        }
        tagManagerArgs({
            'eventCategory' : this.state.recargaEvent.eventCategory,
            'eventAction': this.state.recargaEvent.eventAction,
            'valor': (this.state.otroValor)?this.state.otroValor:this.state.recargaEvent.eventLabel
        })

        if (this.validator.allValid()) {
            this.props.submit(data);
        } else {
            this.setState({loading:false});
            this.validator.showMessages();
            // rerender to show messages for the first time
            this.forceUpdate();
        }


    }

    componentWillReceiveProps(prevProps){

        if(prevProps.responseData.success === false){
            this.setState({
                error: true,
                messageError : prevProps.responseData.error
            });

            if(prevProps.responseData.redirect !== "" && prevProps.responseData.redirect !== undefined){
                window.location.href = (prevProps.responseData.redirect)
            }
        }

        if(prevProps.data.amount !== ""){
            this.setState({
                amount : prevProps.data.amount,
                loading: prevProps.loadingButton
            })
        }
    }

    componentWillMount() {

       let valorUrl =  getUrlVars(window.location.search).valor_recarga || cookies.get('valor_url')
       let numeroUrl =  getUrlVars(window.location.search).numero || cookies.get('numero_url')

        if(valorUrl == undefined || numeroUrl == undefined){
        } else {
            this.setState({
                otroValor : valorUrl,
                number : numeroUrl,
                loading: true
            })

            // this.props.submit({
            //     number:this.state.number,
            //     amount:this.state.otroValor
            // });
        }

    }

    componentDidMount() {
        // console.log(this.props.data)

       if( this.props.data.amount     !== undefined ||
           this.props.data.amount     !== null ||
           this.props.data.amount     !== ""){

           this.setState({amount:this.props.data.amount })
       }
       if(
           this.props.data.number !== undefined ||
           this.props.data.number !== null ||
           this.props.data.number !== "0"){

           this.setState({number: this.props.data.number })
       }

        if(
            this.state.amount !== undefined && this.state.amount !== "" && this.state.amount !== null &&
            this.state.number !== undefined &&  this.state.number !==  null && this.state.number !== ""
    ){
            this.props.submit({
                number:this.state.number,
                amount:this.state.amount
            });
        }
    }

    render() {
        return (
            <div className={"col-12 col-sm-8 px-0 col-md-10 col-lg-8 col-xl-6 m-auto hc f02 cont-form-main py-3 "} >
                <form className={"wc"} style={{maxWidth:"360px"}} onSubmit={this.submitForm}>
                    <div className="col-12 text-center my-3 d-md-none">
                        <img src="/img/isologo.png" alt="" style={{maxHeight: "35px"}}/>
                    </div>
                    <h1 className={"fl wc text-center mb-3"}>Recarga en línea</h1>

                    <div className="col-12 my-3">
                        <CampoIcon
                            icon={"mobile"}
                            placeholder={"Ingresa tu línea Movistar a Recargar"}
                            type={"cleave"}
                            options={{
                                blocks: [3, 3, 4],
                                numericOnly: true,
                                rawValueTrimPrefix: true,
                            }}
                            name={"number"}
                            error={ this.validator.message('número celular', this.state.number, 'required|numero') ||
                                    this.state.messageError
                            }
                            showError={
                                    this.validator.message('número celular', this.state.number, 'required|numero') !== undefined ||
                                    this.state.messageError !== ""

                            }
                            value={this.state.number}
                            onChange={(e) => this.setValue(e)}
                        />
                    </div>
                    <div className="col-12 text-center ">
                        <p className={"mb-0"}>¿Qué valor deseas recargar?</p>
                    </div>
                    <div className="col-12 px-md-4 mb-2 f01 botonera px-sm-0 px-2">
                        <div className="col-4 px-0">
                            <Btnmonto
                                disabled={this.props.loading || this.state.amount === "3000" || this.state.loading}
                                value={3000}
                                action={this.setNumber}
                                monto={"3.000"}
                                active={this.state.amount === "3000"}
                            />
                        </div>
                        <div className="col-4 px-0">
                            <Btnmonto
                                disabled={this.props.loading || this.state.amount === "6000" || this.state.loading}
                                value={6000}
                                action={this.setNumber}
                                monto={"6.000"}
                                active={this.state.amount === "6000"}
                            />
                        </div>
                        <div className="col-4 px-0">
                            <Btnmonto
                                disabled={this.props.loading || this.state.amount === "15000" || this.state.loading}
                                value={15000}
                                action={this.setNumber}
                                monto={'15.000'}
                                active={this.state.amount === "15000"}
                            />
                        </div>
                        <div className="col-4 px-0">
                            <Btnmonto
                                disabled={this.props.loading || this.state.amount === "25000" || this.state.loading}
                                value={25000}
                                action={this.setNumber}
                                monto={'25.000'}
                                active={this.state.amount === "25000"}
                            />
                        </div>
                        <div className="col-4 px-0">
                            <Btnmonto
                                disabled={this.props.loading || this.state.amount === "40000" || this.state.loading}
                                value={40000}
                                action={this.setNumber}
                                monto={'40.000'}
                                active={this.state.amount === "40000"}
                            />
                        </div>
                        <div className="col-4 px-0">
                            <Btnmonto
                                disabled={this.props.loading || this.state.amount === "50000" || this.state.loading}
                                value={50000}
                                action={this.setNumber}
                                monto={'50.000'}
                                active={this.state.amount === "50000"}
                            />
                        </div>
                    </div>

                    <div className="col-12 text-center">
                        <p>Otro valor</p>
                    </div>
                    <div className="col-12 mb-3">
                        <CampoIcon
                            placeholder={"Valor mín. $3.000 - valor máx. $100.000"}
                            type={"cleave"}
                            options={{
                                prefix: '$',
                                noImmediatePrefix: true,
                                numeral: true,
                                numeralPositiveOnly: true,
                                rawValueTrimPrefix: true,
                                numeralThousandsGroupStyle: 'thousand',
                                numeralDecimalScale:0
                            }}
                            id={"otroValor"}
                            name={"amount"}
                            error={this.validator.message('monto', this.state.amount, 'required|monto')}
                            showError={this.validator.message('monto', this.state.amount, 'required|monto') !== undefined}
                            value={this.state.otroValor}
                            onChange={(e) => this.setValue(e)}
                        />
                    </div>

                    <div className="cont-btn-t px-3 ">
                        <button className={`btn-t col-12 ${this.state.loading ? "loading" : ""}`}>
                            {this.state.loading ? "" : "Siguiente"}
                        </button>
                    </div>
                </form>

                <div className="col-12 pb-4 mt-4" style={{flex:"0 0 auto"}}>
                    <Metodos/>
                </div>

                <div className="banner-movil d-md-none wc pb-4 position-relative">
                    <BannerMovil/>
                    <div className="terminos-movil">
                        <a className={"cb d-block"}
                           href="http://www.movistar.co/documents/117302439/404124706/TC+Recargas+Julio+2019.pdf/25e2a633-2799-ad29-0df8-db1a5c2d7c06?_ga=2.221829904.989233219.1570490592-999207625.1545090213"
                           target={"_blank"}
                           rel="nofollow">Aplican términos y condiciones</a>
                    </div>
                </div>

                <div className="col-12 m-auto f01 px-0 d-md-none py-2" style={{flex:"0 0 auto"}}>
                    <p className={" mb-0"}>Pagos Procesados por </p>
                    <img
                         src="/img/epayco.png"
                         className={" ml-2"} alt="logo-epayco"
                         style={{maxHeight: "25px"}}
                    />
                </div>

            </div>
        )
    }
}

export default Formulario;
